/* eslint-disable @typescript-eslint/naming-convention */
export enum PermissionType {
  Administrador = "ADM",
  AdministradorBasic = "ADMB",
  Basico = "B",
  Externo = "EXT",
  Normal = "N",
  BasicoPlus = "NEDL",
  NormalGrupos = "NG",
  SimplesPlus = "S",
  Simples = "SN",
  SimplesLight = "SL",
  None = ""
}
