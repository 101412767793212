import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { firstValueFrom } from "rxjs";
import Swal from "sweetalert2";

@Injectable({
  providedIn: "root"
})
export class ConfirmationService {
  constructor (
    private translateService: TranslateService) { }

  async confirmDelete (): Promise<boolean> {
    const result = await Swal.fire({
      title: await firstValueFrom(this.translateService.get("confirmations.delete.title")),
      text: await firstValueFrom(this.translateService.get("confirmations.delete.text")),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#f46a6a",
      confirmButtonText: await firstValueFrom(this.translateService.get("confirmations.delete.confirmButton")),
      cancelButtonText: await firstValueFrom(this.translateService.get("confirmations.delete.cancelButton")),
    });
    return result.value || false;
  }

  async confirmDeactivation (): Promise<boolean> {
    const result = await Swal.fire({
      title: await firstValueFrom(this.translateService.get("confirmations.deactivation.title")),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34c38f",
      confirmButtonText: await firstValueFrom(this.translateService.get("confirmations.deactivation.confirmButton")),
      cancelButtonColor: "#f46a6a",
      cancelButtonText: await firstValueFrom(this.translateService.get("confirmations.deactivation.cancelButton")),
    });
    return result.value || false;
  }

  async confirmActivation (): Promise<boolean> {
    const result = await Swal.fire({
      title: await firstValueFrom(this.translateService.get("confirmations.activation.title")),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34c38f",
      confirmButtonText: await firstValueFrom(this.translateService.get("confirmations.activation.confirmButton")),
      cancelButtonColor: "#f46a6a",
      cancelButtonText: await firstValueFrom(this.translateService.get("confirmations.activation.cancelButton")),
    });
    return result.value || false;
  }

  async confirmAccountEmail (): Promise<boolean> {
    const result = await Swal.fire({
      title: await firstValueFrom(this.translateService.get("confirmations.accountEmail.title")),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34c38f",
      confirmButtonText: await firstValueFrom(this.translateService.get("confirmations.accountEmail.confirmButton")),
      cancelButtonColor: "#f46a6a",
      cancelButtonText: await firstValueFrom(this.translateService.get("confirmations.accountEmail.cancelButton")),
    });
    return result.value || false;
  }

  async confirmUserEmail (): Promise<boolean> {
    const result = await Swal.fire({
      title: await firstValueFrom(this.translateService.get("confirmations.userEmail.title")),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34c38f",
      confirmButtonText: await firstValueFrom(this.translateService.get("confirmations.userEmail.confirmButton")),
      cancelButtonColor: "#f46a6a",
      cancelButtonText: await firstValueFrom(this.translateService.get("confirmations.userEmail.cancelButton")),
    });
    return result.value || false;
  }

  async confirmResetPassword (): Promise<boolean> {
    const result = await Swal.fire({
      title: await firstValueFrom(this.translateService.get("confirmations.resetPass.title")),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34c38f",
      confirmButtonText: await firstValueFrom(this.translateService.get("confirmations.resetPass.confirmButton")),
      cancelButtonColor: "#f46a6a",
      cancelButtonText: await firstValueFrom(this.translateService.get("confirmations.resetPass.cancelButton")),
    });
    return result.value || false;
  }

  async confirmResendInvite (): Promise<boolean> {
    const result = await Swal.fire({
      title: await firstValueFrom(this.translateService.get("confirmations.resendInvite.title")),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34c38f",
      confirmButtonText: await firstValueFrom(this.translateService.get("confirmations.resendInvite.confirmButton")),
      cancelButtonColor: "#f46a6a",
      cancelButtonText: await firstValueFrom(this.translateService.get("confirmations.resendInvite.cancelButton")),
    });
    return result.value || false;
  }

  async confirmCreateSharing (): Promise<boolean> {
    const result = await Swal.fire({
      title: await firstValueFrom(this.translateService.get("confirmations.createSharing.title")),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34c38f",
      confirmButtonText: await firstValueFrom(this.translateService.get("confirmations.createSharing.confirmButton")),
      cancelButtonColor: "#f46a6a",
      cancelButtonText: await firstValueFrom(this.translateService.get("confirmations.createSharing.cancelButton")),
    });
    return result.value || false;
  }

  async confirmRemoveSharing (): Promise<boolean> {
    const result = await Swal.fire({
      title: await firstValueFrom(this.translateService.get("confirmations.removeSharing.title")),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#f46a6a",
      confirmButtonText: await firstValueFrom(this.translateService.get("confirmations.removeSharing.confirmButton")),
      cancelButtonText: await firstValueFrom(this.translateService.get("confirmations.removeSharing.cancelButton")),
    });
    return result.value || false;
  }

  async confirmDeleteAssets (): Promise<boolean> {
    const result = await Swal.fire({
      title: await firstValueFrom(this.translateService.get("confirmations.deleteAssets.title")),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#f46a6a",
      confirmButtonText: await firstValueFrom(this.translateService.get("confirmations.deleteAssets.confirmButton")),
      cancelButtonText: await firstValueFrom(this.translateService.get("confirmations.deleteAssets.cancelButton")),
    });
    return result.value || false;
  }

  async confirmDeleteAllAssets (): Promise<boolean> {
    const result = await Swal.fire({
      title: await firstValueFrom(this.translateService.get("confirmations.deleteAllAssets.title")),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#f46a6a",
      confirmButtonText: await firstValueFrom(this.translateService.get("confirmations.deleteAllAssets.confirmButton")),
      cancelButtonText: await firstValueFrom(this.translateService.get("confirmations.deleteAllAssets.cancelButton")),
    });
    return result.value || false;
  }

  async confirmDeleteAsset (): Promise<boolean> {
    const result = await Swal.fire({
      title: await firstValueFrom(this.translateService.get("confirmations.deleteAsset.title")),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#f46a6a",
      confirmButtonText: await firstValueFrom(this.translateService.get("confirmations.deleteAsset.confirmButton")),
      cancelButtonText: await firstValueFrom(this.translateService.get("confirmations.deleteAsset.cancelButton")),
    });
    return result.value || false;
  }

  async confirmDeleteGalleryItem (): Promise<boolean> {
    const result = await Swal.fire({
      title: await firstValueFrom(this.translateService.get("confirmations.deleteGalleryItem.title")),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#f46a6a",
      confirmButtonText: await firstValueFrom(this.translateService.get("confirmations.deleteGalleryItem.confirmButton")),
      cancelButtonText: await firstValueFrom(this.translateService.get("confirmations.deleteGalleryItem.cancelButton")),
    });
    return result.value || false;
  }

  async confirmCancelUpload (): Promise<boolean> {
    const result = await Swal.fire({
      title: await firstValueFrom(this.translateService.get("confirmations.cancelUpload.title")),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34c38f",
      confirmButtonText: await firstValueFrom(this.translateService.get("confirmations.cancelUpload.confirmButton")),
      cancelButtonColor: "#f46a6a",
      cancelButtonText: await firstValueFrom(this.translateService.get("confirmations.cancelUpload.cancelButton")),
    });
    return result.value || false;
  }

  async confirmRemoveDownloadQueueItem (): Promise<boolean> {
    const result = await Swal.fire({
      title: await firstValueFrom(this.translateService.get("confirmations.downloadQueueItem.title")),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#f46a6a",
      confirmButtonText: await firstValueFrom(this.translateService.get("confirmations.downloadQueueItem.confirmButton")),
      cancelButtonText: await firstValueFrom(this.translateService.get("confirmations.downloadQueueItem.cancelButton")),
    });
    return result.value || false;
  }

  async confirmRemoveDownloadQueueAll (): Promise<boolean> {
    const result = await Swal.fire({
      title: await firstValueFrom(this.translateService.get("confirmations.downloadQueueAll.title")),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#f46a6a",
      confirmButtonText: await firstValueFrom(this.translateService.get("confirmations.downloadQueueAll.confirmButton")),
      cancelButtonText: await firstValueFrom(this.translateService.get("confirmations.downloadQueueAll.cancelButton")),
    });
    return result.value || false;
  }

  async confirmRemoveDownloadQueueProcessing (): Promise<boolean> {
    const result = await Swal.fire({
      title: await firstValueFrom(this.translateService.get("confirmations.removeDownloadQueue.title")),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#f46a6a",
      confirmButtonText: await firstValueFrom(this.translateService.get("confirmations.removeDownloadQueue.confirmButton")),
      cancelButtonText: await firstValueFrom(this.translateService.get("confirmations.removeDownloadQueue.cancelButton")),
    });
    return result.value || false;
  }

  async confirmCancelDownloadQueueProcessing (): Promise<boolean> {
    const result = await Swal.fire({
      title: await firstValueFrom(this.translateService.get("confirmations.downloadQueueCancelProcessing.title")),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#f46a6a",
      confirmButtonText: await firstValueFrom(this.translateService.get("confirmations.downloadQueueCancelProcessing.confirmButton")),
      cancelButtonText: await firstValueFrom(this.translateService.get("confirmations.downloadQueueCancelProcessing.cancelButton")),
    });
    return result.value || false;
  }

  async confirmMoveAssets (): Promise<boolean> {
    const result = await Swal.fire({
      title: await firstValueFrom(this.translateService.get("confirmations.moveAsset.title")),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34c38f",
      confirmButtonText: await firstValueFrom(this.translateService.get("confirmations.moveAsset.confirmButton")),
      cancelButtonColor: "#f46a6a",
      cancelButtonText: await firstValueFrom(this.translateService.get("confirmations.moveAsset.cancelButton")),
    });
    return result.value || false;
  }

  async confirmCopyAssets (): Promise<boolean> {
    const result = await Swal.fire({
      title: await firstValueFrom(this.translateService.get("confirmations.copyAsset.title")),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34c38f",
      confirmButtonText: await firstValueFrom(this.translateService.get("confirmations.copyAsset.confirmButton")),
      cancelButtonColor: "#f46a6a",
      cancelButtonText: await firstValueFrom(this.translateService.get("confirmations.copyAsset.cancelButton")),
    });
    return result.value || false;
  }

  async confirmFinishApproval (): Promise<boolean> {
    const result = await Swal.fire({
      title: await firstValueFrom(this.translateService.get("confirmations.finishApproval.title")),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34c38f",
      confirmButtonText: await firstValueFrom(this.translateService.get("confirmations.finishApproval.confirmButton")),
      cancelButtonColor: "#f46a6a",
      cancelButtonText: await firstValueFrom(this.translateService.get("confirmations.finishApproval.cancelButton")),
    });
    return result.value || false;
  }

  async confirmDeleteApprovalFile (): Promise<boolean> {
    const result = await Swal.fire({
      title: await firstValueFrom(this.translateService.get("confirmations.deleteApprovalFile.title")),
      text: await firstValueFrom(this.translateService.get("confirmations.deleteApprovalFile.text")),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#f46a6a",
      confirmButtonText: await firstValueFrom(this.translateService.get("confirmations.deleteApprovalFile.confirmButton")),
      cancelButtonText: await firstValueFrom(this.translateService.get("confirmations.deleteApprovalFile.cancelButton")),
    });
    return result.value || false;
  }

  async confirmResolveComment (): Promise<boolean> {
    const result = await Swal.fire({
      title: await firstValueFrom(this.translateService.get("confirmations.resolveComment.title")),
      text: await firstValueFrom(this.translateService.get("confirmations.resolveComment.text")),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#f46a6a",
      confirmButtonText: await firstValueFrom(this.translateService.get("confirmations.resolveComment.confirmButton")),
      cancelButtonText: await firstValueFrom(this.translateService.get("confirmations.resolveComment.cancelButton")),
    });
    return result.value || false;
  }

  async confirmDeleteComment (): Promise<boolean> {
    const result = await Swal.fire({
      title: await firstValueFrom(this.translateService.get("confirmations.deleteComment.title")),
      text: await firstValueFrom(this.translateService.get("confirmations.deleteComment.text")),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#f46a6a",
      confirmButtonText: await firstValueFrom(this.translateService.get("confirmations.deleteComment.confirmButton")),
      cancelButtonText: await firstValueFrom(this.translateService.get("confirmations.deleteComment.cancelButton")),
    });
    return result.value || false;
  }

  async confirmApproveValidationSomeResolved (): Promise<boolean> {
    const result = await Swal.fire({
      title: await firstValueFrom(this.translateService.get("confirmations.approveValidationSomeResolved.title")),
      text: await firstValueFrom(this.translateService.get("confirmations.approveValidationSomeResolved.text")),
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#f46a6a",
      confirmButtonText: await firstValueFrom(this.translateService.get("confirmations.approveValidationSomeResolved.confirmButton")),
      cancelButtonText: await firstValueFrom(this.translateService.get("confirmations.approveValidationSomeResolved.cancelButton")),
    });
    return result.value || false;
  }

  async confirmApproveValidationAllResolved (): Promise<boolean> {
    const result = await Swal.fire({
      title: await firstValueFrom(this.translateService.get("confirmations.approveValidationAllResolved.title")),
      text: await firstValueFrom(this.translateService.get("confirmations.approveValidationAllResolved.text")),
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#f46a6a",
      confirmButtonText: await firstValueFrom(this.translateService.get("confirmations.approveValidationAllResolved.confirmButton")),
      cancelButtonText: await firstValueFrom(this.translateService.get("confirmations.approveValidationAllResolved.cancelButton")),
    });
    return result.value || false;
  }
}
