import { DOCUMENT } from "@angular/common";
import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { PermissionType } from "@models/auth/permission-type";
import { Notification } from "@models/support/notification";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { ConfigService } from "@services/support/config.service";
import { EventService } from "@services/support/event.service";
import { InsightsService } from "@services/support/insights.service";
import { LanguageService } from "@services/support/language.service";
import { Logger } from "@services/support/logger.service";
import { NotificationService } from "@services/support/notification.service";
import accountSelectors from "@state/account/selectors";
import authActions from "@state/auth/actions";
import { State } from "@state/models";
import releaseNoteActions from "@state/release-notes/actions";
import selectedAssetSelectors from "@state/selected-asset/selectors";
import { UserState } from "@state/user/models";
import userSelectors from "@state/user/selectors";
import { CookieService } from "ngx-cookie-service";
import { ToastrService } from "ngx-toastr";
import { firstValueFrom, Subscription } from "rxjs";

@UntilDestroy()
@Component({
  selector: "app-topbar",
  templateUrl: "./topbar.component.html",
  styleUrls: ["./topbar.component.scss"]
})
export class TopbarComponent implements OnInit, OnDestroy {
  @Input() sharing: boolean;
  @Output() settingsButtonClicked = new EventEmitter();
  @Output() assetsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();
  element: any;
  cookieValue: any;
  flagvalue: any;
  countryName: any;
  valueset: any;
  openMobileMenu = false;
  notifications: Notification[] = [];
  unreadNotificationsCount = 0;
  user$ = this.store.select(userSelectors.user);
  accounts$ = this.store.select(accountSelectors.accounts);
  account$ = this.store.select(accountSelectors.currentAccount);
  fileIds$ = this.store.select(selectedAssetSelectors.fileIds);
  assetSelectionRoles = [PermissionType.Administrador, PermissionType.AdministradorBasic, PermissionType.Normal, PermissionType.NormalGrupos, PermissionType.BasicoPlus];
  subs: Subscription;
  currentAccountId: number;
  currentAccountName: string;
  colors = ["bg-danger", "bg-info", "bg-pink", "primary", "bg-secondary", "bg-success", "bg-warning"];
  randomColor: string;

  image = {
    bigLogo: this.configService.getUrl("logo"),
    smallLogo: this.configService.getUrl("logo-mobile")
  };

  listLang = [
    { text: "Português", flag: "assets/images/flags/brazil.jpg", lang: "pt-BR" },
    { text: "English", flag: "assets/images/flags/us.jpg", lang: "en-US" },
    { text: "Español", flag: "assets/images/flags/spain.jpg", lang: "es-ES" }
  ];

  constructor (
    @Inject(DOCUMENT) private document: any,
    private router: Router,
    private logger: Logger,
    private insightsService: InsightsService,
    private store: Store<State>,
    private toastr: ToastrService,
    private eventService: EventService,
    private configService: ConfigService,
    private notifService: NotificationService,
    public languageService: LanguageService,
    public translate: TranslateService,
    private cookiesService: CookieService) {
  }

  async ngOnInit () {
    this.openMobileMenu = false;
    this.element = document.documentElement;
    this.cookieValue = this.cookiesService.get("lang");

    this.configService.bigLogoChanged.pipe(untilDestroyed(this)).subscribe(url => this.image.bigLogo = url);
    this.configService.smallLogoChanged.pipe(untilDestroyed(this)).subscribe(url => this.image.smallLogo = url);

    const val = this.listLang.filter(x => x.lang === this.cookieValue);
    this.countryName = val.map(element => element.text);

    if (val.length === 0) {
      if (this.flagvalue === undefined) {
        this.valueset = "assets/images/flags/brazil.jpg";
      }
    } else {
      this.flagvalue = val.map(element => element.flag);
    }

    const downloadQueueTitle = await firstValueFrom(this.translate.get("alerts.processedQueue.title"));
    const downloadQueueText = await firstValueFrom(this.translate.get("alerts.processedQueue.text"));

    this.subs = this.eventService.subscribe("load_notifications", (notifications: Notification[]) => {
      const currentUnreadNotifications = this.notifications.filter(x => !x.isRead);
      const newUnreadNotifications = notifications.filter(x => !x.isRead);

      this.unreadNotificationsCount = newUnreadNotifications.length;
      this.notifications = notifications;

      const temp = currentUnreadNotifications.map(x => JSON.stringify(x));
      const except = newUnreadNotifications.filter(x => !temp.includes(JSON.stringify(x)));

      if (except.some(n => n.area === "release")) {
        this.store.dispatch(releaseNoteActions.setNewReleaseNote(true));
      }

      if (except.some(n => n.area === "download-queue")) {
        this.toastr.success(downloadQueueText, downloadQueueTitle);
      }
    });

    this.account$.pipe(untilDestroyed(this)).subscribe(async account => {
      if (account && account.accountId !== this.currentAccountId) {
        this.currentAccountId = account.accountId;
        this.currentAccountName = account.name;
      }
    });

    this.randomColor = this.colors[this.rnd(0, 6)];
  }

  ngOnDestroy (): void {
    if (this.subs) {
      this.subs.unsubscribe();
    }
  }

  rnd (min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  getNotifUrl (notif: Notification) {
    switch (notif.area) {
      case "release":
        return "/release-notes";
      case "download-queue":
        return "/cm/download-queue";
      default:
        return undefined;
    }
  }

  hasAssetSelectButton (user: UserState | null) {
    return user && this.assetSelectionRoles.some(role => user.role === role);
  }

  async notificationClicked (isOpened: boolean) {
    if (isOpened && this.unreadNotificationsCount > 0) {
      try {
        await this.notifService.markAsRead();
        this.unreadNotificationsCount = 0;
      }
      catch { }
    }
  }

  setLanguage (text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.languageService.setLanguage(lang);
  }

  toggleRightSidebar () {
    this.settingsButtonClicked.emit();
  }

  toggleRightSidebarAsset () {
    this.assetsButtonClicked.emit();
  }

  toggleMobileMenu (event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  logout () {
    localStorage.clear();
    sessionStorage.clear();
    this.cookiesService.delete("sessionId", "/");
    this.store.dispatch(authActions.signedOut());
    this.insightsService.clearUserId();
    this.logger.info("user signedout... redirecting to login page");
    this.router.navigate(["/login"]);
  }

  fullscreen () {
    document.body.classList.toggle("fullscreen-enable");

    if (!document.fullscreenElement && !this.element.mozFullScreenElement && !this.element.webkitFullscreenElement) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        this.element.mozRequestFullScreen();
      } else if (this.element.webkitRequestFullscreen) {
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) {
        this.element.msRequestFullscreen();
      }
    }
    else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        this.document.msExitFullscreen();
      }
    }
  }
}
