import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@environment";
import { AssetTerm } from "@models/asset/asset-term";
import { firstValueFrom } from "rxjs";
import { tap } from "rxjs/operators";
import { Logger } from "../support/logger.service";

@Injectable({
  providedIn: "root"
})
export class AssetTermService {
  constructor (
    private logger: Logger,
    private http: HttpClient) {
  }

  getTerms (accountId: number): Promise<AssetTerm> {
    return firstValueFrom(
      this.http.get<AssetTerm>(`${environment.catalog.url}/assets/${accountId}/terms`).pipe(tap({
        next: () => undefined,
        error: err => this.logger.error(err)
      }))
    );
  }

  updateTerms (accountId: number, enableTermsOfUse: boolean, enableAllTerms: boolean, term: string, enablePublicTermsOfUse: boolean, enablePublicAllTerms: boolean, publicTerm: string): Promise<unknown> {
    return firstValueFrom(
      this.http.post(`${environment.catalog.url}/assets/${accountId}/terms`, {
        accountId,
        enableTermsOfUse,
        enableAllTerms,
        term,
        enablePublicTermsOfUse,
        enablePublicAllTerms,
        publicTerm
      }).pipe(tap({
        next: () => undefined,
        error: err => this.logger.error(err)
      }))
    );
  }
}
