import { createSelector } from "@ngrx/store";
import { State } from "@state/models";
import { ReleaseNotesState } from "./models";

const slice = (state: State) => state.releaseNote;

export const error = createSelector(slice,
  (state: ReleaseNotesState) => state.error
);

export const hasNewRelease = createSelector(slice,
  (state: ReleaseNotesState) => state.hasNewRelease
);

export default {
  error,
  hasNewRelease
};
