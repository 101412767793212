import { createAction, props } from "@ngrx/store";

export default {
  selectedAssetLoading: createAction("[Selected Assets] loading"),
  selectedAssetLoaded: createAction("[Selected Assets] Selected Assets loaded successfully"),
  add: createAction("[Selected Assets] Asset added", props<{ fileId: number }>()),
  remove: createAction("[Selected Assets] Asset removed", props<{ fileId: number }>()),
  reset: createAction("[Selected Assets] reseted"),
  error: createAction("[Selected Assets] error", props<{ error: string }>())
};
