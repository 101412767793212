import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Customer } from "@models/account/customer";
import { LanguageService } from "@services/support/language.service";

@Component({
  selector: "app-auth-layout",
  templateUrl: "./auth-layout.component.html",
  styleUrls: ["./auth-layout.component.scss"]
})
export class AuthLayoutComponent implements OnInit {
  year: number = new Date().getFullYear();

  constructor (
    private activatedRoute: ActivatedRoute,
    public languageService: LanguageService) {
  }

  ngOnInit (): void {
    const customer = this.activatedRoute.snapshot.data.customer as Customer;

    if (customer) {
      this.addStylesheet(customer.styleName);
    }
  }

  addStylesheet (href: string) {
    const linkEl = document.createElement("link");
    linkEl.setAttribute("rel", "stylesheet");
    linkEl.setAttribute("href", href);
    document.head.appendChild(linkEl);
    return linkEl;
  }
}
