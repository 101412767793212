import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@environment";
import { Team } from "@models/account/team";
import { TermsOfUse } from "@models/auth/terms-of-use";
import { Url } from "@models/support/url";
import { Invite } from "@models/user/invite";
import { UserFull } from "@models/user/user-full";
import { UserPermission } from "@models/user/user-permission";
import { UserSimple } from "@models/user/user-simple";
import { UserState } from "@state/user/models";
import { firstValueFrom } from "rxjs";
import { tap } from "rxjs/operators";
import { IpService } from "../support/ip.service";
import { Logger } from "../support/logger.service";

@Injectable({
  providedIn: "root"
})
export class UserService {
  constructor (
    private logger: Logger,
    private ipService: IpService,
    private http: HttpClient) {
  }

  getUserData (): Promise<UserState> {
    return firstValueFrom(
      this.http.get<UserState>(`${environment.identity.url}/user/data`).pipe(tap({
        next: () => undefined,
        error: err => this.logger.error(err)
      }))
    );
  }

  setUserData (name: any, phone: any, imageUrl: any): Promise<unknown> {
    return firstValueFrom(
      this.http.put(`${environment.identity.url}/user/data`, {
        name,
        phone,
        imageUrl
      }).pipe(tap({
        next: () => undefined,
        error: err => this.logger.error(err)
      }))
    );
  }

  setUserConfig (isMarketingNewsletterAccepted: any, isAssetCreationAccepted: any, isAssetUpdateAccepted: any): Promise<unknown> {
    return firstValueFrom(
      this.http.put(`${environment.identity.url}/user/config`, {
        isMarketingNewsletterAccepted,
        isAssetCreationAccepted,
        isAssetUpdateAccepted
      }).pipe(tap({
        next: () => undefined,
        error: err => this.logger.error(err)
      }))
    );
  }

  getTermsOfUse (): Promise<TermsOfUse> {
    return firstValueFrom(
      this.http.get<TermsOfUse>(`${environment.identity.url}/terms?domain=${location.hostname}`).pipe(tap({
        next: () => undefined,
        error: err => this.logger.error(err)
      }))
    );
  }

  async setTermsOfUse (termOfUseId: any): Promise<unknown> {
    let ip;

    try {
      const myip = await this.ipService.getIp();
      ip = myip?.ip ?? undefined;
    } catch {
    }

    return firstValueFrom(
      this.http.put(`${environment.identity.url}/terms`, {
        termOfUseId,
        ip
      }).pipe(tap({
        next: () => undefined,
        error: err => this.logger.error(err)
      }))
    );
  }

  async uploadAvatar (file: File): Promise<Url> {
    const formData = new FormData();
    formData.append("file", file);

    return firstValueFrom(
      this.http.post<Url>(`${environment.identity.url}/user/avatar`, formData).pipe(tap({
        next: () => undefined,
        error: err => this.logger.error(err)
      }))
    );
  }

  getAllUsers (): Promise<UserSimple[]> {
    return firstValueFrom(
      this.http.get<UserSimple[]>(`${environment.identity.url}/user/all`).pipe(tap({
        next: () => undefined,
        error: err => this.logger.error(err)
      }))
    );
  }

  getAdminAllUsers (...args: any): Promise<UserFull[]> {
    return firstValueFrom(
      this.http.get<UserFull[]>(`${environment.identity.url}/user/admin/all?teamId=${args.join("")}`).pipe(tap({
        next: () => undefined,
        error: err => this.logger.error(err)
      }))
    );
  }

  getMasterAllUsers (...args: any): Promise<UserFull[]> {
    return firstValueFrom(
      this.http.get<UserFull[]>(`${environment.identity.url}/user/master/all?accountId=${args.join("")}`).pipe(tap({
        next: () => undefined,
        error: err => this.logger.error(err)
      }))
    );
  }

  getUserTeams (userId: number): Promise<Team[]> {
    return firstValueFrom(
      this.http.get<Team[]>(`${environment.catalog.url}/teams/user/${userId}/list`).pipe(tap({
        next: () => undefined,
        error: err => this.logger.error(err)
      }))
    );
  }

  setUserStatus (userId: number, activate: boolean): Promise<unknown> {
    const action = activate ? "activate" : "deactivate";
    return firstValueFrom(
      this.http.put(`${environment.identity.url}/user/${userId}/${action}`, {}).pipe(tap({
        next: () => undefined,
        error: err => this.logger.error(err)
      }))
    );
  }

  setUserAccountStatus (userId: number, activate: boolean): Promise<unknown> {
    const action = activate ? "activate" : "deactivate";
    return firstValueFrom(
      this.http.put(`${environment.identity.url}/user/${userId}/account/${action}`, {}).pipe(tap({
        next: () => undefined,
        error: err => this.logger.error(err)
      }))
    );
  }

  sendNotification (userId: number): Promise<unknown> {
    return firstValueFrom(
      this.http.post(`${environment.identity.url}/user/${userId}/confirmation`, {}).pipe(tap({
        next: () => undefined,
        error: err => this.logger.error(err)
      }))
    );
  }

  resendInvite (email: string): Promise<unknown> {
    return firstValueFrom(
      this.http.post(`${environment.identity.url}/user/invite/resend`,
        {
          email,
          domain: location.host
        }).pipe(tap({
          next: () => undefined,
          error: err => this.logger.error(err)
        }))
    );
  }

  resetPassword (userId: number): Promise<unknown> {
    return firstValueFrom(
      this.http.post(`${environment.identity.url}/user/${userId}/reset`, {}).pipe(tap({
        next: () => undefined,
        error: err => this.logger.error(err)
      }))
    );
  }

  deleteUser (userId: number): Promise<unknown> {
    return firstValueFrom(
      this.http.delete(`${environment.identity.url}/user/${userId}`).pipe(tap({
        next: () => undefined,
        error: err => this.logger.error(err)
      }))
    );
  }

  deleteUserAccount (userId: number): Promise<unknown> {
    return firstValueFrom(
      this.http.delete(`${environment.identity.url}/user/${userId}/account`).pipe(tap({
        next: () => undefined,
        error: err => this.logger.error(err)
      }))
    );
  }

  deleteUserInvite (email: string): Promise<unknown> {
    return firstValueFrom(
      this.http.post(`${environment.identity.url}/user/invite/delete`,
        {
          email
        }).pipe(tap({
          next: () => undefined,
          error: err => this.logger.error(err)
        }))
    );
  }

  getUser (userId: number): Promise<UserFull> {
    return firstValueFrom(
      this.http.get<UserFull>(`${environment.identity.url}/user/${userId}`).pipe(tap({
        next: () => undefined,
        error: err => this.logger.error(err)
      }))
    );
  }

  getInvite (inviteId: number): Promise<Invite> {
    return firstValueFrom(
      this.http.get<Invite>(`${environment.identity.url}/user/invite/${inviteId}`).pipe(tap({
        next: () => undefined,
        error: err => this.logger.error(err)
      }))
    );
  }

  getUserPermissions (userId?: number): Promise<UserPermission[]> {
    if (userId === undefined) {
      return Promise.resolve([]);
    }

    return firstValueFrom(
      this.http.get<UserPermission[]>(`${environment.identity.url}/user/${userId}/permissions`).pipe(tap({
        next: () => undefined,
        error: err => this.logger.error(err)
      }))
    );
  }

  async createUser (user: UserFull, permissions: any[]): Promise<unknown> {
    const result = await firstValueFrom(
      this.http.post<{ userId: number }>(`${environment.identity.url}/user/master`,
        {
          ...user,
          permissions
        }).pipe(tap({
          next: () => undefined,
          error: err => this.logger.error(err)
        })));

    return this.sendNotification(result.userId);
  }

  inviteUser (user: UserFull, accountUserPermissionId: number, teams: number[]): Promise<unknown> {
    return firstValueFrom(
      this.http.post(`${environment.identity.url}/user/invite`,
        {
          email: user.email,
          userExpirationDate: user.expirationDate,
          loginType: user.loginType,
          domain: location.host,
          accountUserPermissionId,
          teams,
          storeId: user.storeId
        }).pipe(tap({
          next: () => undefined,
          error: err => this.logger.error(err)
        })));
  }

  updateInvite (user: UserFull, accountUserPermissionId: number, teams: number[]): Promise<unknown> {
    return firstValueFrom(
      this.http.put(`${environment.identity.url}/user/invite`,
        {
          email: user.email,
          userExpirationDate: user.expirationDate,
          loginType: user.loginType,
          storeId: user.storeId,
          accountUserPermissionId,
          teams
        }).pipe(tap({
          next: () => undefined,
          error: err => this.logger.error(err)
        })));
  }

  updateMasterUser (user: UserFull, permissions: any[]): Promise<unknown> {
    return firstValueFrom(
      this.http.put(`${environment.identity.url}/user/master/${user.userId}`,
        {
          ...user,
          permissions
        }).pipe(tap({
          next: () => undefined,
          error: err => this.logger.error(err)
        }))
    );
  }

  updateAdminUser (user: UserFull, accountUserPermissionId: number, teams: number[]): Promise<unknown> {
    return firstValueFrom(
      this.http.put(`${environment.identity.url}/user/admin/${user.userId}`,
        {
          fullName: user.fullName,
          email: user.email,
          phone: user.phone,
          expirationDate: user.expirationDate,
          storeId: user.storeId,
          accountUserPermissionId,
          teams
        }).pipe(tap({
          next: () => undefined,
          error: err => this.logger.error(err)
        }))
    );
  }
}
