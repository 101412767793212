import { HttpClient, HttpContext } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@environment";
import { CurrentQueueItem } from "@models/download-queue/current-queue-item";
import { ProcessedQueue } from "@models/download-queue/processed-queue";
import { ProcessingHistory } from "@models/download-queue/processing-history";
import { NGX_LOADING_BAR_IGNORED } from "@ngx-loading-bar/http-client";
import { firstValueFrom } from "rxjs";
import { tap } from "rxjs/operators";
import { Logger } from "../support/logger.service";

@Injectable({
  providedIn: "root"
})
export class DownloadQueueService {
  constructor (
    private logger: Logger,
    private http: HttpClient) {
  }

  getCurrentQueue (): Promise<{ totalSize: string; items: CurrentQueueItem[] }> {
    return firstValueFrom(
      this.http.get<{ totalSize: string; items: CurrentQueueItem[] }>(`${environment.catalog.url}/download-queue/current`).pipe(tap({
        next: () => undefined,
        error: err => this.logger.error(err)
      }))
    );
  }

  addItemToCurrentQueue (itemId: number, fileId?: number): Promise<{ count: number }> {
    return this.addItemsToCurrentQueue([itemId], fileId);
  }

  addItemsToCurrentQueue (itemIds: number[], fileId?: number): Promise<{ count: number }> {
    return firstValueFrom(
      this.http.post<{ count: number }>(`${environment.catalog.url}/download-queue/current`, {
        itemIds,
        fileId
      }).pipe(tap({
        next: () => undefined,
        error: err => this.logger.error(err)
      }))
    );
  }

  removeItemFromCurrentQueue (id: string) {
    return this.removeItemsFromCurrentQueue([id]);
  }

  removeItemsFromCurrentQueue (ids: string[]) {
    return firstValueFrom(
      this.http.post(`${environment.catalog.url}/download-queue/current/remove`, {
        ids
      }).pipe(tap({
        next: () => undefined,
        error: err => this.logger.error(err)
      }))
    );
  }

  getProcessedQueue (): Promise<ProcessedQueue[]> {
    return firstValueFrom(
      this.http.get<ProcessedQueue[]>(`${environment.catalog.url}/download-queue/processed`, { context: new HttpContext().set(NGX_LOADING_BAR_IGNORED, true) }).pipe(tap({
        next: () => undefined,
        error: err => this.logger.error(err)
      }))
    );
  }

  getProcessingHistory (queueId: string): Promise<ProcessingHistory> {
    return firstValueFrom(
      this.http.get<ProcessingHistory>(`${environment.catalog.url}/download-queue/${queueId}/history`, { context: new HttpContext().set(NGX_LOADING_BAR_IGNORED, true) }).pipe(tap({
        next: () => undefined,
        error: err => this.logger.error(err)
      }))
    );
  }

  getDownloadUrl (queueId: string, itemExternalAccessId?: string): Promise<{ url: string }> {
    return firstValueFrom(
      this.http.get<{ url: string }>(`${environment.catalog.url}/download-queue/${queueId}/download?ieai=${itemExternalAccessId || ""}`).pipe(tap({
        next: () => undefined,
        error: err => this.logger.error(err)
      }))
    );
  }

  processCurrentQueue (): Promise<{ processedQueueId: string }> {
    return firstValueFrom(
      this.http.post<{ processedQueueId: string }>(`${environment.catalog.url}/download-queue/process/current`, {}).pipe(tap({
        next: () => undefined,
        error: err => this.logger.error(err)
      }))
    );
  }

  reprocessQueue (queueId: string) {
    return firstValueFrom(
      this.http.post(`${environment.catalog.url}/download-queue/reprocess/${queueId}`, {}).pipe(tap({
        next: () => undefined,
        error: err => this.logger.error(err)
      }))
    );
  }

  removeQueue (queueId: string) {
    return firstValueFrom(
      this.http.post(`${environment.catalog.url}/download-queue/remove/${queueId}`, {}).pipe(tap({
        next: () => undefined,
        error: err => this.logger.error(err)
      }))
    );
  }

  cancelProcessingQueue (queueId: string) {
    return firstValueFrom(
      this.http.post(`${environment.catalog.url}/download-queue/cancel/${queueId}`, {}).pipe(tap({
        next: () => undefined,
        error: err => this.logger.error(err)
      }))
    );
  }

  updateCurrentQueueItemSize (itemId: string, size: string): Promise<unknown> {
    return firstValueFrom(
      this.http.put(`${environment.catalog.url}/download-queue/current/update/${itemId}/${size}`, {}).pipe(tap({
        next: () => undefined,
        error: err => this.logger.error(err)
      }))
    );
  }

  getQueueItems (queueId: string): Promise<CurrentQueueItem[]> {
    return firstValueFrom(
      this.http.get<CurrentQueueItem[]>(`${environment.catalog.url}/download-queue/${queueId}/items`).pipe(tap({
        next: () => undefined,
        error: err => this.logger.error(err)
      }))
    );
  }

  getFromKey (key: string): Promise<ProcessedQueue> {
    return firstValueFrom(
      this.http.get<ProcessedQueue>(`${environment.catalog.url}/download-queue/${key}/sharing/detail`).pipe(tap({
        next: () => undefined,
        error: err => this.logger.error(err)
      }))
    );
  }
}
