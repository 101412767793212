<div class="right-bar" (clickOutside)="hide()" [exclude]="'.right-bar-toggle'">
  <ngx-simplebar class="h-100">
    <div class="rightbar-title px-3 py-4">
      <a href="javascript:void(0);" class="right-bar-toggle float-end" (click)="hide();">
        <i class="mdi mdi-close noti-icon"></i>
      </a>
      <h5 class="m-0" translate>general.accounts</h5>
    </div>
    <hr class="my-0">
    <div class="p-2">
      <table class="table table-nowrap align-middle table-hover mb-0">
        <tbody>
          <tr class="pointer" [class.table-active]="account.current" (click)="select(account.accountId)" *ngFor="let account of accounts$ | async; let i = index">
            <td>
              <div class="avatar-xs">
                <span class="avatar-title rounded-circle {{ colors[i] }}">
                  {{ account.name.charAt(0) }}
                </span>
              </div>
            </td>
            <td class="ps-0 w-100">
              <h5 class="font-size-14 mb-0">{{ account.name }}</h5>
              <small>{{ account.subdomain || "app.huntag.com.br" }}</small>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </ngx-simplebar>
</div>

<div class="rightbar-overlay"></div>
