import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-sharing-layout",
  templateUrl: "./sharing-layout.component.html",
  styleUrls: ["./sharing-layout.component.scss"]
})
export class SharingLayoutComponent implements OnInit {
  constructor () {
  }

  ngOnInit (): void {
  }
}
