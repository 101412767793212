import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class ExtensionService {
  constructor () { }

  contains (ext?: string): boolean {
    if (ext) {
      return this.getList().includes(ext.toLowerCase());
    }
    else {
      return false;
    }
  }

  isImage (url: string) {
    url = this.getFilename(url?.toLowerCase() || "").filename;

    return url.endsWith("jpg")
      || url.endsWith("jpeg")
      || url.endsWith("gif")
      || url.endsWith("bmp")
      || url.endsWith("png");
  }

  isPsd (url: string) {
    url = this.getFilename(url?.toLowerCase() || "").filename;

    return url.endsWith("psd")
      || url.endsWith("psb");
  }

  isTiff (url: string) {
    url = this.getFilename(url?.toLowerCase() || "").filename;

    return url.endsWith("tiff")
      || url.endsWith("tif");
  }

  isValidImageType (url: string) {
    return this.isImage(url)
      || this.isPsd(url)
      || this.isTiff(url);
  }

  isAudio (url: string) {
    url = this.getFilename(url?.toLowerCase() || "").filename;

    return url.endsWith("mp3")
      || url.endsWith("ogg");
  }

  isVideo (url: string) {
    url = this.getFilename(url?.toLowerCase() || "").filename;

    return url.endsWith("mp4")
      || url.endsWith("m4a")
      || url.endsWith("m4v")
      || url.endsWith("f4v")
      || url.endsWith("f4a")
      || url.endsWith("m4b")
      || url.endsWith("m4r")
      || url.endsWith("f4b")
      || url.endsWith("mov")
      || url.endsWith("3gp")
      || url.endsWith("3gp2")
      || url.endsWith("3g2")
      || url.endsWith("3gpp")
      || url.endsWith("3gpp2")
      || url.endsWith("ogg")
      || url.endsWith("oga")
      || url.endsWith("ogv")
      || url.endsWith("ogx")
      || url.endsWith("wmv")
      || url.endsWith("wma")
      || url.endsWith("asf")
      || url.endsWith("webm")
      || url.endsWith("flv")
      || url.endsWith("avi")
      || url.endsWith("mpg")
      || url.endsWith("mpeg")
      || url.endsWith("m2v")
      || url.endsWith("avi")
      || url.endsWith("mp2")
      || url.endsWith("mkv");
  }

  isPlayableVideo (url: string) {
    url = this.getFilename(url?.toLowerCase() || "").filename;

    return url.endsWith("mp4")
      || url.endsWith("mov")
      || url.endsWith("mkv");
  }

  isOffice (url: string) {
    url = this.getFilename(url?.toLowerCase() || "").filename;

    return url.endsWith("ppt")
      || url.endsWith("pptx")
      || url.endsWith("doc")
      || url.endsWith("docx")
      || url.endsWith("xls")
      || url.endsWith("xlsx");
  }

  isPDF (url: string) {
    url = this.getFilename(url?.toLowerCase() || "").filename;

    return url.endsWith("pdf");
  }

  getFilename (url: string): { filename: string; name: string; extension: string } {
    let filename = url.substring(url.lastIndexOf("/") + 1);
    filename = filename.split("?")[0];
    filename = filename.split("#")[0];
    const name = filename.substring(0, filename.lastIndexOf("."));
    const extension = filename.split(".").pop() || "";

    return {
      filename,
      name,
      extension
    };
  }

  getList (): string[] {
    return [
      "3ds",
      "aac",
      "aep",
      "aepx",
      "aet",
      "aex",
      "ai",
      "aiff",
      "alac",
      "as",
      "asf",
      "asp",
      "aspx",
      "avi",
      "bat",
      "blend",
      "bmp",
      "cad",
      "cdr",
      "cptx",
      "css",
      "csv",
      "default",
      "dfx",
      "diff",
      "divx",
      "doc",
      "docm",
      "docx",
      "dot",
      "dotm",
      "dotx",
      "dpx",
      "dsw",
      "dv",
      "dvd-ms",
      "dvr",
      "emf",
      "en2",
      "eps",
      "exe",
      "fl4",
      "fla",
      "flac",
      "flv",
      "gif",
      "htm",
      "html",
      "ico",
      "id",
      "indd",
      "java",
      "jpeg",
      "jpg",
      "js",
      "json",
      "key",
      "kml",
      "kmz",
      "ma",
      "max",
      "mht",
      "mkv",
      "mov",
      "mp2",
      "mp2a",
      "mp3",
      "mp3g",
      "mp4",
      "mp4a",
      "mpeg",
      "mpg",
      "odp",
      "ods",
      "odt",
      "otf",
      "pcm",
      "pdf",
      "pez",
      "php",
      "png",
      "pot",
      "potm",
      "potx",
      "pps",
      "ppsm",
      "ppsx",
      "ppt",
      "ppta",
      "pptm",
      "pptx",
      "prn",
      "prproj",
      "psd",
      "quiz",
      "rar",
      "raw",
      "rmvb",
      "rtf",
      "skp",
      "slk",
      "story",
      "studio",
      "svg",
      "tga",
      "tiff",
      "tif",
      "ttf",
      "txt",
      "wab",
      "wav",
      "wma",
      "wmf",
      "wmv",
      "wrl",
      "xla",
      "xlam",
      "xls",
      "xlsb",
      "xlsm",
      "xlsx",
      "xlt",
      "xltm",
      "xltx",
      "xml",
      "xsd",
      "zip",
      "ztl"
    ];
  }
}
