import dev from "environments/environment.dev";

export const environment = {
  ...dev,
  production: false,
  name: "staging",
  maxGalleryItems: 10,
  maxGalleryItemsKit: 100,
  notificationPollInterval: 30000,
  apiKey: "e8f100ac8d3e491b8214ba3d532c9e9c",
  storageUrl: "https://huntaghom.blob.core.windows.net",
  identity: { url: "https://api-hml.huntag.com.br/identity" },
  catalog: { url: "https://api-hml.huntag.com.br/catalog" },
  account: { url: "https://api-hml.huntag.com.br/account" },
  aprovio: { url: "https://api-hml.huntag.com.br/aprovio" },
  report: { url: "https://api-hml.huntag.com.br/report" }
};
