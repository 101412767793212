import { createSelector } from "@ngrx/store";
import { LoadingState, State } from "@state/models";
import { AuthState } from "./models";

const slice = (state: State) => state.auth;

export const isLoading = createSelector(slice,
  (auth: AuthState) => auth.state === LoadingState.loading
);

export const isLoaded = createSelector(slice,
  (auth: AuthState) => auth.state === LoadingState.loaded
);

export const error = createSelector(slice,
  (auth: AuthState) => auth.error
);

export const accessToken = createSelector(slice,
  (auth: AuthState) => auth.accessToken
);

export default {
  isLoading,
  isLoaded,
  error,
  accessToken
};
