<div class="container-fluid p-0">
  <div class="row g-0">
    <div class="col-xl-9">
      <div class="auth-full-bg pt-lg-5 p-4">
        <div class="w-100">
          <div class="bg-overlay custom"></div>
        </div>
      </div>
    </div>

    <div class="col-xl-3">
      <div class="auth-full-page-content custom p-md-5 p-4">
        <div class="w-100">
          <div class="d-flex flex-column h-100">
            <div class="mb-4 mb-md-5">
              <a routerLink="/" class="d-block logo custom"></a>
            </div>

            <router-outlet></router-outlet>

            <div class="mt-4 mt-sm-auto text-center">
              <p class="mb-0" [innerHtml]="'login.copyright' | translate:{ year: year }"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>