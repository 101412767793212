import { Component, OnInit, ViewChild } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { RightsidebarAssetComponent } from "../rightsidebar-asset/rightsidebar-asset.component";

@Component({
  selector: "app-vertical",
  templateUrl: "./vertical.component.html",
  styleUrls: ["./vertical.component.scss"]
})
export class VerticalComponent implements OnInit {
  @ViewChild("rightsidebarAsset") rightsidebarAsset: RightsidebarAssetComponent;
  isCondensed = false;

  constructor (router: Router) {
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        document.body.classList.remove("sidebar-enable");
      }
    });
  }

  ngOnInit () {
    document.body.removeAttribute("data-layout");
    document.body.removeAttribute("data-topbar");
    document.body.removeAttribute("data-layout-size");
    document.body.classList.remove("sidebar-enable");
    document.body.classList.remove("auth-body-bg");
    document.body.classList.remove("vertical-collpsed");
    document.body.removeAttribute("data-sidebar-size");
  }

  onSettingsButtonClicked () {
    document.body.classList.toggle("right-bar-enabled");
  }

  onAssetsButtonClicked () {
    this.rightsidebarAsset.show();
  }

  onToggleMobileMenu () {
    this.isCondensed = !this.isCondensed;
    document.body.classList.toggle("sidebar-enable");
    document.body.classList.toggle("vertical-collpsed");

    if (window.screen.width <= 768) {
      document.body.classList.remove("vertical-collpsed");
    }
  }
}
