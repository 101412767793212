// @ts-nocheck
import { Component, OnInit } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngrx/store";
import { ConfigService } from "@services/support/config.service";
import accountSelectors from "@state/account/selectors";
import { LoadingState, State } from "@state/models";
import userSelectors from "@state/user/selectors";
import { environment } from "environments/environment";
import { NgxFreshChatService } from "ngx-freshchat";
import { take } from "rxjs/operators";

@UntilDestroy()
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit {
  user$ = this.store.select(userSelectors.user);

  constructor (
    private store: Store<State>,
    private configService: ConfigService,
    private chat: NgxFreshChatService) { }

  async ngOnInit () {
    this.configService.initTheme();

    if (environment.production) {
      this.initChat();
    }
  }

  private initChat () {
    this.user$.pipe(untilDestroyed(this)).subscribe(async (user) => {
      if (user.state !== LoadingState.loaded) {
        try {
          //usuário deslogado, desliga o chat
          this.chat.destroy();
        } catch {
        }
      } else {
        const subs = this.store
          .select(accountSelectors.currentAccount)
          .subscribe(async (account) => {
            if (account) {
              //usuário logado, inicia o chat
              this.chat.init({
                token: environment.freshChat.token,
                host: environment.freshChat.host,
                siteId: "Huntag V2",
                firstName: user.firstName,
                email: user.email,
                config: {
                  headerProperty: {
                    direction: "ltr"
                  }
                }
              }).pipe(take(1)).subscribe(() => {
                this.chat.setUserProperties({
                  userId: user.userId,
                  accountId: account.accountId,
                  accountName: account.name
                }).pipe(take(1)).subscribe();
                subs.unsubscribe();
              });
            }
          });
      }
    });
  }
}
