import { Component, OnInit, ViewChild } from "@angular/core";
import { RightsidebarAssetComponent } from "../rightsidebar-asset/rightsidebar-asset.component";

@Component({
  selector: "app-horizontal",
  templateUrl: "./horizontal.component.html",
  styleUrls: ["./horizontal.component.scss"]
})
export class HorizontalComponent implements OnInit {
  @ViewChild("rightsidebarAsset") rightsidebarAsset: RightsidebarAssetComponent;
  constructor () { }

  ngOnInit () {
    document.body.setAttribute("data-layout", "horizontal");
    document.body.setAttribute("data-topbar", "dark");
    document.body.removeAttribute("data-sidebar");
    document.body.removeAttribute("data-layout-size");
    document.body.removeAttribute("data-keep-enlarged");
    document.body.removeAttribute("data-sidebar-small");
  }

  onSettingsButtonClicked () {
    document.body.classList.toggle("right-bar-enabled");
  }

  onAssetsButtonClicked () {
    this.rightsidebarAsset.show();
  }
}
