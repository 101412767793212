import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Ip } from "@models/support/ip";
import { firstValueFrom } from "rxjs";
import { tap } from "rxjs/operators";
import { Logger } from "./logger.service";

@Injectable({
  providedIn: "root"
})
export class IpService {
  constructor (
    private logger: Logger,
    private http: HttpClient) {
  }

  getIp (): Promise<Ip> {
    return firstValueFrom(
      this.http.jsonp<Ip>("https://api.ipify.org?format=jsonp", "callback")
        .pipe(tap({
          next: () => undefined,
          error: err => this.logger.error(err)
        }))
    );
  }
}
