import { Component } from "@angular/core";
import formatError from "@helpers/format-error";
import "@helpers/promise-extensions";
import { select, Store } from "@ngrx/store";
import { AccountService } from "@services/account/account.service";
import { AssetTermService } from "@services/asset/asset-term.service";
import { AuthService } from "@services/identity/auth.service";
import { UserService } from "@services/identity/user.service";
import { InsightsService } from "@services/support/insights.service";
import { Logger } from "@services/support/logger.service";
import accountSelectors, { default as selectors } from "@state/account/selectors";
import authActions from "@state/auth/actions";
import featureActions from "@state/features/actions";
import { State } from "@state/models";
import termAssetActions from "@state/term-asset/actions";
import userActions from "@state/user/actions";
import { CookieService } from "ngx-cookie-service";
import { firstValueFrom, take } from "rxjs";

@Component({
  selector: "app-rightsidebar",
  templateUrl: "./rightsidebar.component.html",
  styleUrls: ["./rightsidebar.component.scss"]
})

export class RightsidebarComponent {
  accounts$ = this.store.select(selectors.accounts);
  colors = ["bg-danger", "bg-info", "bg-pink", "primary", "bg-secondary", "bg-success", "bg-warning", "bg-danger", "bg-info", "bg-pink", "primary", "bg-secondary", "bg-success", "bg-warning"];

  constructor (
    private logger: Logger,
    private authService: AuthService,
    private userService: UserService,
    private assetTermService: AssetTermService,
    private accountService: AccountService,
    private insightsService: InsightsService,
    private cookieService: CookieService,
    private store: Store<State>) { }

  async select (accountId: number) {
    try {
      const currentAcount = await firstValueFrom(this.store.pipe(select(accountSelectors.currentAccount), take(1)));
      const account = await this.accountService.getAccount(accountId);

      await this.accountService.setUserAccount(accountId);

      if (account && account.subdomain !== currentAcount?.subdomain) {
        this.logout();
        await Promise.delay(500);
        const protocol = account.subdomain?.includes("localhost") ? "http://" : "https://";
        window.location.href = `${protocol}${account.subdomain}`;
      }
      else {
        await this.refreshToken();
        await this.loadUserFeatures(accountId);
        await this.loadTermAsset(accountId);
        await this.getUserData();
        window.location.href = "/";
      }

      this.hide();
    }
    catch (err: any) {
      this.store.dispatch(userActions.error(formatError(err)));
    }
  }

  logout () {
    localStorage.clear();
    sessionStorage.clear();
    this.cookieService.delete("sessionId", "/");
    this.store.dispatch(authActions.signedOut());
    this.insightsService.clearUserId();
    this.logger.info("user signedout... redirecting to login page");
  }

  hide () {
    document.body.classList.remove("right-bar-enabled");
  }

  private async getUserData () {
    this.store.dispatch(userActions.userPropsLoading());
    const user = await this.userService.getUserData();
    this.store.dispatch(userActions.userPropsLoaded({ user }));
  }

  private async loadUserFeatures (accountId: number) {
    this.store.dispatch(featureActions.featuresLoading());
    const features = await this.accountService.getFeatures(accountId);
    this.store.dispatch(featureActions.featuresLoaded({ features }));
  }

  private async loadTermAsset (accountId: number) {
    this.store.dispatch(termAssetActions.termAssetLoading());
    const assetTerm = await this.assetTermService.getTerms(accountId);
    this.store.dispatch(termAssetActions.termAssetLoaded({ assetTerm }));
  }

  private async refreshToken () {
    this.store.dispatch(authActions.signingIn());
    const auth = await this.authService.refreshToken();
    this.store.dispatch(authActions.signedIn({ auth }));
  }
}
