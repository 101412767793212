import { createReducer, on } from "@ngrx/store";
import authActions from "@state/auth/actions";
import { LoadingState } from "@state/models";
import actions from "./actions";
import { ReleaseNotesState } from "./models";

const initialState: ReleaseNotesState = {
  hasNewRelease: false,
  state: LoadingState.init,
  error: null
};

export const releaseNotesReducer = createReducer(
  initialState,
  on(actions.setNewReleaseNote, (state, action) => ({ ...state, hasNewRelease: action.value })),
  on(actions.reset, () => ({ ...initialState })),
  on(actions.error, (state, action) => ({ ...state, state: LoadingState.error, error: action.error })),
  on(authActions.signedOut, () => ({ ...initialState }))
);
