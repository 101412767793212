/* eslint-disable no-console */
import { Injectable } from "@angular/core";

/**
 * Simple logger system with the possibility of registering custom outputs.
 *
 * 4 different log levels are provided, with corresponding methods:
 * - debug   : for debug information
 * - info    : for informative status of the application (success, ...)
 * - warning : for non-critical errors that do not prevent normal application behavior
 * - error   : for critical errors that prevent normal application behavior
 *
 */

/**
 * The possible log levels.
 * LogLevel.Off is never emitted and only used with Logger.level property to disable logs.
 */
export enum LogLevel {
  off = 0,
  error,
  warning,
  info,
  debug
}

@Injectable({
  providedIn: "root"
})
export class Logger {
  /**
   * Current logging level.
   * Set it to LogLevel.Off to disable logs completely.
   */
  static level = LogLevel.debug;

  /**
   * Enables production mode.
   * Sets logging level to LogLevel.Warning.
   */
  static enableProdMode () {
    Logger.level = LogLevel.warning;
  }

  /**
   * Logs messages or objects  with the debug level.
   * Works the same as console.log().
   */
  debug (...objects: any[]) {
    this.log(console.log, LogLevel.debug, objects);
  }

  /**
   * Logs messages or objects  with the info level.
   * Works the same as console.log().
   */
  info (...objects: any[]) {
    this.log(console.info, LogLevel.info, objects);
  }

  /**
   * Logs messages or objects  with the warning level.
   * Works the same as console.log().
   */
  warn (...objects: any[]) {
    this.log(console.warn, LogLevel.warning, objects);
  }

  /**
   * Logs messages or objects  with the error level.
   * Works the same as console.log().
   */
  error (...objects: any[]) {
    this.log(console.error, LogLevel.error, objects);
  }

  private log (func: any, level: LogLevel, objects: any[]) {
    if (level <= Logger.level) {
      func.apply(console, objects);
    }
  }
}
