import { createSelector } from "@ngrx/store";
import { LoadingState, State } from "@state/models";
import { FeatureState } from "./models";

const slice = (state: State) => state.feature;

export const isLoading = createSelector(slice,
  (state: FeatureState) => state.state === LoadingState.loading
);

export const isLoaded = createSelector(slice,
  (state: FeatureState) => state.state === LoadingState.loaded
);

export const error = createSelector(slice,
  (state: FeatureState) => state.error
);

export const features = createSelector(slice,
  (state: FeatureState) => state.items
);

export default {
  isLoading,
  isLoaded,
  error,
  features
};
