import { ActionReducerMap, MetaReducer } from "@ngrx/store";
import { environment } from "environments/environment";
import { accountReducer } from "./account/reducers";
import { aprovioDashboardReducer } from "./aprovio/dashboard/reducers";
import { authReducer } from "./auth/reducers";
import { featureReducer } from "./features/reducers";
import { imageProfileReducer } from "./image-profile/reducers";
import { debug, localStorageSyncReducer } from "./meta-reducers";
import { State } from "./models";
import { queueCountReducer } from "./queue/reducers";
import { releaseNotesReducer } from "./release-notes/reducers";
import { selectedAssetReducer } from "./selected-asset/reducers";
import { termAssetReducer } from "./term-asset/reducers";
import { userReducer } from "./user/reducers";

export const metaReducers: MetaReducer<any>[] =
  environment.production
    ? [localStorageSyncReducer]
    : [debug, localStorageSyncReducer];

export const reducers: ActionReducerMap<State> = {
  auth: authReducer,
  user: userReducer,
  account: accountReducer,
  feature: featureReducer,
  queueCount: queueCountReducer,
  termAsset: termAssetReducer,
  selectedAsset: selectedAssetReducer,
  releaseNote: releaseNotesReducer,
  imageProfile: imageProfileReducer,
  aprovioDashboard: aprovioDashboardReducer
};
