import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { Logger } from "@services/support/logger.service";
import { State } from "@state/models";
import selectors from "@state/user/selectors";
import { firstValueFrom } from "rxjs";
import { take } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class TermsGuard implements CanActivate, CanActivateChild {
  constructor (
    private router: Router,
    private logger: Logger,
    private store: Store<State>) {
  }

  async canActivate (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean | UrlTree> {

    const termOfUseAccepted = await firstValueFrom(
      this.store.pipe(
        select(selectors.termOfUseAccepted),
        take(1)
      ));

    if (!termOfUseAccepted) {
      this.logger.info("user not accepted terms of use... redirecting");
      return this.router.parseUrl("/terms");
    }

    return true;
  }

  async canActivateChild (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    return await this.canActivate(route, state);
  }
}
