import { createReducer, on } from "@ngrx/store";
import authActions from "@state/auth/actions";
import { LoadingState } from "@state/models";
import actions from "./actions";
import { AprovioDashboardState } from "./models";

const initialState: AprovioDashboardState = {
  viewType: "g",
  state: LoadingState.init,
  error: null
};

export const aprovioDashboardReducer = createReducer(
  initialState,
  on(actions.viewTypeLoaded, (state, action) => ({ ...state, viewType: action.viewType, state: LoadingState.loaded, error: null })),
  on(actions.reset, () => ({ ...initialState })),
  on(actions.error, (state, action) => ({ ...state, state: LoadingState.error, error: action.error })),
  on(authActions.signedOut, () => ({ ...initialState }))
);
