import { HttpErrorResponse } from "@angular/common/http";

export default (err: HttpErrorResponse) => {
  let error = "";

  switch (err.status) {
    case 400:
      error = `exceptions.${err.error.detail}`;
      break;
    case 500:
    default:
      error = "exceptions.generic_exception";
      break;
  }

  return { error };
};
