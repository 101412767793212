<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <div class="navbar-brand-box">
        <a routerLink="/" class="logo logo-light">
          <span class="logo-sm">
            <img [src]="image.smallLogo" alt="" height="26">
          </span>
          <span class="logo-lg">
            <img [src]="image.bigLogo" alt="" height="35">
          </span>
        </a>
      </div>

      <!-- <img src="assets/images/logo.png" height="35" *ngIf="sharing"> -->

      <button type="button" class="btn btn-sm px-3 font-size-16 header-item" id="vertical-menu-btn" (click)="toggleMobileMenu($event)" *ngIf="!sharing">
        <i class="fa fa-fw fa-bars"></i>
      </button>

      <!-- App Search-->
      <!-- <form class="app-search d-none d-lg-block" *ngIf="!sharing">
        <div class="position-relative">
          <input type="text" class="form-control" placeholder="{{'header.search' | translate}}">
          <span class="bx bx-search-alt"></span>
        </div>
      </form> -->
    </div>

    <div class="d-flex">
      <!-- App Search Mobile-->
      <!-- <div class="dropdown d-inline-block d-lg-none ms-2" ngbDropdown *ngIf="!sharing">
        <button type="button" class="btn header-item noti-icon" id="page-header-search-dropdown" data-toggle="dropdown" aria-haspopup="true" ngbDropdownToggle aria-expanded="false">
          <i class="mdi mdi-magnify"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" ngbDropdownMenu aria-labelledby="page-header-search-dropdown">

          <form class="p-3">
            <div class="m-0">
              <div class="input-group">
                <input type="text" class="form-control" placeholder="Search ..." aria-label="Recipient's username">
                <div class="input-group-append">
                  <button class="btn btn-primary" type="submit"><i class="mdi mdi-magnify"></i></button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div> -->

      <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item" id="page-header-user-dropdown" ngbDropdownToggle>
          <img *ngIf="flagvalue !== undefined" src="{{flagvalue}}" alt="Header Language" class="align-top" height="16">
          <img *ngIf="flagvalue === undefined" src="{{valueset}}" alt="Header Language" class="align-top" height="16">
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <a href="javascript:void(0);" class="dropdown-item notify-item" *ngFor="let item of listLang" (click)="setLanguage(item.text, item.lang, item.flag)" [ngClass]="{'active': cookieValue === item.lang}">
            <img src="{{item.flag}}" alt="user-image" class="me-1" height="12"> <span class="align-middle">{{item.text}}</span>
          </a>
        </div>
      </div>

      <div class="dropdown d-none d-lg-inline-block ms-1">
        <button type="button" class="btn header-item noti-icon" data-toggle="fullscreen" (click)="fullscreen()">
          <i class="bx bx-fullscreen"></i>
        </button>
      </div>

      <div class="dropdown d-inline-block" ngbDropdown *ngIf="!sharing" (openChange)="notificationClicked($event)">
        <button type="button" class="btn header-item noti-icon position-relative" id="page-header-notifications-dropdown" ngbDropdownToggle>
          <i class="bx bx-bell" [class.bx-tada]="unreadNotificationsCount > 0"></i>
          <span class="badge bg-danger rounded-pill" *ngIf="unreadNotificationsCount > 0">{{unreadNotificationsCount}}</span>
        </button>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" aria-labelledby="page-header-notifications-dropdown" ngbDropdownMenu *ngIf="notifications.length > 0">
          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0">{{ 'header.notifications.title' | translate}} </h6>
              </div>
            </div>
          </div>
          <ngx-simplebar style="position: relative; max-height: 430px;">
            <a [routerLink]="getNotifUrl(notif)" class="text-reset notification-item" *ngFor="let notif of notifications">
              <div class="media">
                <div class="avatar-xs me-3">
                  <span class="avatar-title bg-primary rounded-circle font-size-16">
                    <i class="bx bx-bell"></i>
                  </span>
                </div>
                <div class="media-body">
                  <h6 class="mt-0 mb-1">{{notif.title}}</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">{{notif.description}}</p>
                    <p class="mb-0"><i class="mdi mdi-clock-outline"></i> {{notif.creationDate | date:"dd/MM/yyyy HH:mm"}}</p>
                  </div>
                </div>
              </div>
            </a>
          </ngx-simplebar>
        </div>
      </div>

      <div class="d-inline-block position-relative" *ngIf="!sharing && hasAssetSelectButton((user$ | async))">
        <button type="button" class="btn header-item noti-icon right-bar-asset-toggle" id="page-header-assets-selected" (click)="toggleRightSidebarAsset()">
          <i class="bx bx-customize" [class.bx-tada]="((fileIds$ | async)?.length || 0) > 0"></i>
          <span class="badge bg-danger rounded-pill" *ngIf="((fileIds$ | async)?.length || 0) > 0">{{(fileIds$ | async)?.length || 0}}</span>
        </button>
      </div>

      <div class="d-inline-block position-relative" *ngIf="(accounts$ | async)?.length !== 1 && !sharing">
        <button type="button" class="btn header-item noti-icon right-bar-toggle" (click)="toggleRightSidebar()">
          <i class="bx bx-cog"></i>
        </button>
      </div>

      <div class="dropdown d-inline-block" ngbDropdown *ngIf="!sharing">
        <button type="button" class="btn header-item d-flex align-items-center" ngbDropdownToggle id="page-header-user-dropdown">
          <div class="avatar-xs" *ngIf="!(user$ | async)?.imageUrl; else avatar">
            <span class="avatar-title rounded-circle {{ randomColor }}">
              {{ ((user$ | async)?.firstName || " ").charAt(0) }}
            </span>
          </div>
          <ng-template #avatar>
            <img class="rounded-circle header-profile-user" src="{{ (user$ | async)?.imageUrl }}" onerror="this.src='assets/images/users/avatar-0.png'">
          </ng-template>
          <div class="d-none d-xl-inline-block text-start" style="width: 75px;">
            <div class="ms-1 lh-1 font-size-13">{{ (user$ | async)?.firstName }}</div>
            <div class="ms-1 text-truncate font-size-11 text-info" [title]="currentAccountName">{{ currentAccountName }}</div>
          </div>
          <i class="mdi mdi-chevron-down d-none d-xl-inline-block ms-2"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <a class="dropdown-item" routerLink="/user/profile"><i class="bx bx-user font-size-16 align-middle me-1"></i>{{ 'header.login.profile' | translate}}</a>
          <a class="dropdown-item" routerLink="/cm/gallery/user/{{ (user$ | async)?.userId }}"><i class="bx bx-photo-album font-size-16 align-middle me-1"></i>{{ 'header.login.gallery' | translate}}</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item text-danger" href="javascript:void(0);" (click)="logout()"><i class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>{{ 'header.login.logout' | translate}}</a>
        </div>
      </div>
    </div>
  </div>
</header>
