
export { };

declare global {
  interface Array<T> {
    findR<U> (predicate: (x: T) => U, childrenPropertyName: string): T;
    remove<U> (predicate: (x: T) => U): any;
    paginate (n: number): any;
  }
}

Array.prototype.paginate = (function (n: number) {
  const pageSize = Math.ceil(this.length / n);

  const array = Array.from({ length: pageSize }, (_, index) => {
    const start = index * n;
    return this.slice(start, start + n);
  });

  return (p?: number) => {
    if (p) {
      return array[p];
    } else {
      return array;
    }
  };
});

Array.prototype.findR = function (predicate: any, childrenPropertyName: string) {
  if (!childrenPropertyName) {
    throw Error("findR requires parameter `childrenPropertyName`");
  }

  const initialFind = this.find(predicate);
  const elementsWithChildren = this.filter(x => x[childrenPropertyName]);

  if (initialFind) {
    return initialFind;
  }
  else if (elementsWithChildren.length) {
    const childElements: any[] = [];

    elementsWithChildren.forEach(x => {
      childElements.push(...x[childrenPropertyName]);
    });

    return childElements.findR(predicate, childrenPropertyName);
  }
  else {
    return undefined;
  }
};

Array.prototype.remove = function (predicate: any) {
  const itemsToRemove = this.filter(predicate);
  const indexes = findPositions(this, itemsToRemove);
  indexes.reverse().forEach((value, index) => this.splice(value, 1));
};

const findPositions = (first: any, second: any) => {
  const indicies: number[] = [];
  first.forEach((element: any, index: number) => {
    if (second.includes(element)) {
      indicies.push(index);
    };
  });
  return indicies;
};
