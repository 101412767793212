import { createSelector } from "@ngrx/store";
import { LoadingState, State } from "@state/models";
import { QueueCountState } from "./models";

const slice = (state: State) => state.queueCount;

export const isLoading = createSelector(slice,
  (state: QueueCountState) => state.state === LoadingState.loading
);

export const isLoaded = createSelector(slice,
  (state: QueueCountState) => state.state === LoadingState.loaded
);

export const error = createSelector(slice,
  (state: QueueCountState) => state.error
);

export const count = createSelector(slice,
  (state: QueueCountState) => state.count
);

export default {
  isLoading,
  isLoaded,
  error,
  count
};
