import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { CookieService } from "ngx-cookie-service";

@Injectable({ providedIn: "root" })
export class LanguageService {
  public languages: string[] = ["pt-BR", "en-US", "es-ES"];

  constructor (public translate: TranslateService, private cookieService: CookieService) {
    let browserLang: string;
    this.translate.addLangs(this.languages);

    if (this.cookieService.check("lang")) {
      browserLang = this.cookieService.get("lang");
    }
    else {
      switch (translate.getBrowserLang()) {
        case "en":
          browserLang = "en-US";
          break;
        case "es":
          browserLang = "es-ES";
          break;
        default:
          browserLang = "pt-BR";
          break;
      }
    }

    this.setLanguage(browserLang);
  }

  public setLanguage (lang: string) {
    this.translate.use(lang);
    this.setCookie(lang);
  }

  private setCookie (lang: string) {
    this.cookieService.set("lang", lang, new Date(2099, 12, 31), "/");
  }
}
