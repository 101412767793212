import { createAction, props } from "@ngrx/store";

export default {
  queueCountLoading: createAction("[Queue Count] loading"),
  queueCountLoaded: createAction("[Queue Count] queue count loaded successfully", props<{ count: number }>()),
  add: createAction("[Queue Count] Queue item added", (count: number = 1) => ({ count })),
  remove: createAction("[Queue Count] Queue item removed", (count: number = 1) => ({ count })),
  reset: createAction("[Queue Count] reseted"),
  error: createAction("[Queue Count] error", props<{ error: string }>())
};
