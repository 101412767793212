import { CommonModule, registerLocaleData } from "@angular/common";
import { HttpBackend, HttpClient, HttpClientJsonpModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import localePt from "@angular/common/locales/pt";
import { ErrorHandler, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouteReuseStrategy, UrlSerializer } from "@angular/router";
import { NgxPicaModule } from "@digitalascetic/ngx-pica";
import { environment } from "@environment";
import { LowerCaseUrlSerializer } from "@helpers/lower-case-url-serializer";
import { ApplicationinsightsAngularpluginErrorService } from "@microsoft/applicationinsights-angularplugin-js";
import { StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { LoadingBarModule } from "@ngx-loading-bar/core";
import { LoadingBarHttpClientModule } from "@ngx-loading-bar/http-client";
import { LoadingBarRouterModule } from "@ngx-loading-bar/router";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { CustomReuseStrategy } from "@services/router-reuse.strategy";
import * as state from "@state/index";
import { NgxFreshChatModule } from "ngx-freshchat";
import { ToastrModule } from "ngx-toastr";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HTTP_NOAUTH } from "./core/interceptors/httpNoAuth";
import { JwtInterceptor } from "./core/interceptors/jwt.interceptor";
import { LayoutsModule } from "./layouts/layouts.module";

export const httpLoaderFactory = (http: HttpClient) => new TranslateHttpLoader(http, "assets/i18n/", ".json");
registerLocaleData(localePt);

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    LoadingBarModule,
    LayoutsModule,
    TranslateModule,
    HttpClientJsonpModule,
    NgxPicaModule,
    NgxFreshChatModule,
    ToastrModule.forRoot(),
    StoreModule.forRoot(state.reducers, { metaReducers: state.metaReducers }),
    TranslateModule.forRoot({ defaultLanguage: "pt-BR", loader: { provide: TranslateLoader, useFactory: httpLoaderFactory, deps: [HttpClient] } }),
    StoreDevtoolsModule.instrument({ name: "Huntag Store", maxAge: 25, logOnly: environment.production })
  ],
  exports: [
  ],
  providers: [
    { provide: UrlSerializer, useClass: LowerCaseUrlSerializer },
    { provide: HTTP_NOAUTH, deps: [HttpBackend], useFactory: (handler: HttpBackend) => new HttpClient(handler) },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: RouteReuseStrategy, useClass: CustomReuseStrategy },
    { provide: ErrorHandler, useClass: ApplicationinsightsAngularpluginErrorService }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
