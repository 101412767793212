import { createReducer, on } from "@ngrx/store";
import authActions from "@state/auth/actions";
import { LoadingState } from "@state/models";
import actions from "./actions";
import { SelectedAssetState } from "./models";

const initialState: SelectedAssetState = {
  fileIds: [],
  state: LoadingState.init,
  error: null
};

export const selectedAssetReducer = createReducer(
  initialState,
  on(actions.selectedAssetLoading, (state) => ({ ...state, state: LoadingState.loading, error: null })),
  on(actions.selectedAssetLoaded, (state) => ({ ...state, state: LoadingState.loaded, error: null })),
  on(actions.add, (state, action) => ({ ...state, fileIds: [...state.fileIds.filter(fileId => fileId !== action.fileId), action.fileId] })),
  on(actions.remove, (state, action) => ({ ...state, fileIds: state.fileIds.filter(fileId => fileId !== action.fileId) })),
  on(actions.reset, () => ({ ...initialState })),
  on(actions.error, (state, action) => ({ ...state, state: LoadingState.error, error: action.error })),
  on(authActions.signedOut, () => ({ ...initialState }))
);
