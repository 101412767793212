import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AngularPlugin } from "@microsoft/applicationinsights-angularplugin-js";
import { environment } from "environments/environment";

@Injectable({
  providedIn: "root"
})
export class InsightsService {
  private angularPlugin = new AngularPlugin();
  // private appInsights = new ApplicationInsights({
  //   config: {
  //     connectionString: environment.appInsightsCnnString,
  //     extensions: [this.angularPlugin],
  //     extensionConfig: {
  //       [this.angularPlugin.identifier]: {
  //         router: this.router,
  //         errorServices: [new ErrorHandler()]
  //       }
  //     }
  //   }
  // });

  constructor (private router: Router) {
    // this.appInsights.loadAppInsights();
    // this.appInsights.addTelemetryInitializer(this.getTelemetryProperties());
  }

  setUserId (userId: number) {
    //this.appInsights.setAuthenticatedUserContext(userId.toString(), undefined, true);
  }

  clearUserId () {
    //this.appInsights.clearAuthenticatedUserContext();
  }

  trackEvent (name: string, properties?: { [key: string]: any }) {
    //this.appInsights.trackEvent({ name }, properties);
  }

  trackMetric (name: string, average: number, properties?: { [key: string]: any }) {
    //this.appInsights.trackMetric({ name, average }, properties);
  }

  trackException (exception: Error, severityLevel?: number) {
    //this.appInsights.trackException({ exception, severityLevel });
  }

  trackTrace (message: string, properties?: { [key: string]: any }) {
    //this.appInsights.trackTrace({ message }, properties);
  }

  private getTelemetryProperties () {
    return (envelope: any) => {
      envelope.data.app = "huntag-webapp";
      envelope.data.env = environment.name;
    };
  }
}
