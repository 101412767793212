import { Component } from "@angular/core";
import { Router } from "@angular/router";
import "@helpers/array-extensions";
import "@helpers/promise-extensions";
import { AssetSelected } from "@models/asset/asset-selected";
import { select, Store } from "@ngrx/store";
import { AssetService } from "@services/item/asset.service";
import { ConfirmationService } from "@services/support/confirmation.service";
import { State } from "@state/models";
import selectedAssetActions from "@state/selected-asset/actions";
import selectedAssetSelectors from "@state/selected-asset/selectors";
import { firstValueFrom } from "rxjs";
import { take } from "rxjs/operators";

@Component({
  selector: "app-rightsidebar-asset",
  templateUrl: "./rightsidebar-asset.component.html",
  styleUrls: ["./rightsidebar-asset.component.scss"]
})

export class RightsidebarAssetComponent {
  assets: AssetSelected[] = [];
  loading: boolean;

  constructor (
    private router: Router,
    private assetService: AssetService,
    private confirmationService: ConfirmationService,
    private store: Store<State>) { }

  async show () {
    try {
      this.loading = true;
      document.body.classList.toggle("right-bar-asset-enabled");

      const fileIds = await firstValueFrom(this.store.pipe(select(selectedAssetSelectors.fileIds), take(1)));
      this.assets = await this.assetService.getSelectedFiles(fileIds);
    }
    catch {
    }
    finally {
      this.loading = false;
    }
  }

  getUrl (asset: AssetSelected) {
    if (!asset.url) {
      return `assets/images/extensions/${asset.extension}.png`;
    } else {
      return asset.url;
    }
  }

  hide (event: any) {
    if (
      event.target.className === "rightbar-asset-overlay" ||
      event.target.className === "mdi mdi-close noti-icon" ||
      event.target.className === "right-bar-asset-toggle float-end") {
      this.assets = [];
      document.body.classList.remove("right-bar-asset-enabled");
    }
  }

  goToNewKitPage (action: string) {
    document.body.classList.remove("right-bar-asset-enabled");
    this.router.navigateByUrl("/cm/upload/asset?action=" + action);
  }

  goToSelectKitPage (action: string) {
    document.body.classList.remove("right-bar-asset-enabled");
    this.router.navigateByUrl("/cm/gallery/find?type=kit&action=" + action);
  }

  async remove (fileId: number) {
    const confirm = await this.confirmationService.confirmDeleteAsset();

    if (confirm) {
      this.store.dispatch(selectedAssetActions.remove({ fileId }));
      this.assets.remove(asset => asset.id === fileId);
    }
  }

  async removeAll () {
    const confirm = await this.confirmationService.confirmDeleteAllAssets();

    if (confirm) {
      this.store.dispatch(selectedAssetActions.reset());
      this.assets = [];

      //garante que se o usuário estiver atualamente na página de criação do kit, ele seja redirecionado para a home pois não existem mais itens selecionados
      if (this.router.url.endsWith("/upload/asset")) {
        document.body.classList.remove("right-bar-asset-enabled");
        this.router.navigate(["/"]);
      }
    }
  }

  onImgError (event: any) {
    event.target.src = "assets/images/extensions/default.png";
  }
}
