import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { HTTP_NOAUTH } from "app/core/interceptors/httpNoAuth";
import { environment } from "environments/environment";
import { firstValueFrom, Subject } from "rxjs";
import { tap } from "rxjs/operators";
import { Logger } from "./logger.service";

@Injectable({
  providedIn: "root"
})
export class ConfigService {
  bigLogoChanged: Subject<string> = new Subject<string>();
  smallLogoChanged: Subject<string> = new Subject<string>();
  loginBkgChanged: Subject<string> = new Subject<string>();
  cssChanged: Subject<boolean> = new Subject<boolean>();

  defaultTheme = {
    primary: "#00b3b9",
    secondary: "#74788d",
    success: "#34c38f",
    info: "#50a5f1",
    warning: "#f1b44c",
    danger: "#f46a6a",
    dark: "#343a40",
    menuBkg: "#f7f9fa",
    menuText: "#6e7274",
    menuTextHover: "#274047"
  };

  constructor (
    private logger: Logger,
    private http: HttpClient,
    @Inject(HTTP_NOAUTH) private nakedHttp: HttpClient) {
  }

  async initTheme () {
    const theme = await this.getThemeFromStorage();

    if (theme) {
      this.setCssProps(theme);
    }
  }

  async getTheme (): Promise<any> {
    const theme = await this.getThemeFromStorage();
    return theme || this.defaultTheme;
  }

  async setTheme (theme: any) {
    await firstValueFrom(
      this.http.post(`${environment.account.url}/config/theme/${location.hostname}`, {
        ...theme
      }).pipe(tap({
        next: () => {
          this.setCssProps(theme);
        },
        error: err => this.logger.error(err)
      }))
    );
  }

  async resetTheme () {
    await firstValueFrom(
      this.http.post(`${environment.account.url}/config/theme/${location.hostname}/reset`, {}).pipe(tap({
        next: () => {
          this.removeCssProps();
        },
        error: err => this.logger.error(err)
      }))
    );
  }

  uploadLogo (file: File, type: string): Promise<unknown> {
    const formData: FormData = new FormData();
    formData.append("file", file);

    return firstValueFrom(
      this.http.post(`${environment.account.url}/config/logo/${location.hostname}/${type}`, formData).pipe(tap({
        next: () => {
          if (type === "big") {
            this.bigLogoChanged.next(this.getUrl("logo"));
          } else {
            this.smallLogoChanged.next(this.getUrl("logo-mobile"));
          }
        },
        error: err => this.logger.error(err)
      }))
    );
  }

  uploadLoginBkg (file: File): Promise<unknown> {
    const formData: FormData = new FormData();
    formData.append("file", file);

    return firstValueFrom(
      this.http.post(`${environment.account.url}/config/login/${location.hostname}`, formData).pipe(tap({
        next: () => {
          this.loginBkgChanged.next(this.getUrl("login-bkg"));
        },
        error: err => this.logger.error(err)
      }))
    );
  }

  uploadCss (file: File): Promise<unknown> {
    const formData: FormData = new FormData();
    formData.append("file", file);

    return firstValueFrom(
      this.http.post(`${environment.account.url}/config/css/${location.hostname}`, formData).pipe(tap({
        next: () => {
          this.cssChanged.next(true);
        },
        error: err => this.logger.error(err)
      }))
    );
  }

  getUrl (type: string) {
    if (type === "css") {
      return `${environment.storageUrl}/auth/${location.hostname}/app.css`;
    }
    else if (type === "theme") {
      return `${environment.storageUrl}/auth/${location.hostname}/theme.json`;
    }
    else {
      const timestamp = (new Date()).getTime();
      return `${environment.storageUrl}/auth/${location.hostname}/${type}.png?t=${timestamp}`;
    }
  }

  async cssExists (): Promise<boolean> {
    try {
      await firstValueFrom(this.nakedHttp.get(this.getUrl("css"), { responseType: "text" }));
      return true;
    }
    catch {
      return false;
    }
  }

  async getThemeFromStorage (): Promise<string | undefined> {
    try {
      const salt = `?${(new Date()).getTime()}`;
      return await firstValueFrom(this.nakedHttp.get<string>(this.getUrl("theme") + salt));
    }
    catch {
      return undefined;
    }
  }

  private setCssProps (theme: any) {
    document.body.style.setProperty("--bs-primary", theme.primary);
    document.body.style.setProperty("--bs-secondary", theme.secondary);
    document.body.style.setProperty("--bs-success", theme.success);
    document.body.style.setProperty("--bs-info", theme.info);
    document.body.style.setProperty("--bs-warning", theme.warning);
    document.body.style.setProperty("--bs-danger", theme.danger);
    document.body.style.setProperty("--bs-dark", theme.dark);
    document.body.style.setProperty("--bs-menu", theme.menuBkg);
    document.body.style.setProperty("--bs-menu-text", theme.menuText);
    document.body.style.setProperty("--bs-menu-text-hover", theme.menuTextHover);
    document.body.classList.add("custom-theme");
  }

  private removeCssProps () {
    document.body.style.removeProperty("--bs-primary");
    document.body.style.removeProperty("--bs-secondary");
    document.body.style.removeProperty("--bs-success");
    document.body.style.removeProperty("--bs-info");
    document.body.style.removeProperty("--bs-warning");
    document.body.style.removeProperty("--bs-danger");
    document.body.style.removeProperty("--bs-dark");
    document.body.style.removeProperty("--bs-menu");
    document.body.style.removeProperty("--bs-menu-text");
    document.body.style.removeProperty("--bs-menu-text-hover");
    document.body.classList.remove("custom-theme");
  }
}
