import { createAction, props } from "@ngrx/store";
import { AuthState } from "./models";

export default {
  signingIn: createAction("[Auth] signing in"),
  signedIn: createAction("[Auth] signed in successfully", props<{ auth: AuthState }>()),
  signedOut: createAction("[Auth] signed out"),
  reset: createAction("[Auth] reseted"),
  error: createAction("[Auth] error", props<{ error: string }>())
};
