import { createReducer, on } from "@ngrx/store";
import authActions from "@state/auth/actions";
import { LoadingState } from "@state/models";
import actions from "./actions";
import { UserState } from "./models";

const initialState: UserState = {
  state: LoadingState.init,
  error: null
};

export const userReducer = createReducer(
  initialState,
  on(actions.userPropsLoading, (state) => ({ ...state, state: LoadingState.loading, error: null })),
  on(actions.userPropsLoaded, (state, action) => ({ ...state, ...action.user, state: LoadingState.loaded, error: null })),
  on(actions.termsOfUseAccepted, (state) => ({ ...state, termOfUseAccepted: true })),
  on(actions.imageUrlUpdated, (state, action) => ({ ...state, imageUrl: action.imageUrl + `?${new Date().getTime()}` })),
  on(actions.reset, () => ({ ...initialState })),
  on(actions.error, (state, action) => ({ ...state, state: LoadingState.error, error: action.error })),
  on(authActions.signedOut, () => ({ ...initialState }))
);
