import { createAction, props } from "@ngrx/store";
import { UserState } from "@state/user/models";

export default {
  userPropsLoading: createAction("[User] loading"),
  userPropsLoaded: createAction("[User] user properties loaded successfully", props<{ user: UserState }>()),
  termsOfUseAccepted: createAction("[User] user accepted terms of use successfully"),
  imageUrlUpdated: createAction("[User] imageUrl updated successfully", props<{ imageUrl: string }>()),
  reset: createAction("[User] reseted"),
  error: createAction("[User] error", props<{ error: string }>())
};
