import { createReducer, on } from "@ngrx/store";
import authActions from "@state/auth/actions";
import { LoadingState } from "@state/models";
import actions from "./actions";
import { AccountState } from "./models";

const initialState: AccountState = {
  items: [],
  state: LoadingState.init,
  error: null
};

export const accountReducer = createReducer(
  initialState,
  on(actions.accountsLoading, (state) => ({ ...state, state: LoadingState.loading, error: null })),
  on(actions.accountsLoaded, (state, action) => ({ ...state, items: action.accounts, state: LoadingState.loaded, error: null })),
  on(actions.reset, () => ({ ...initialState })),
  on(actions.error, (state, action) => ({ ...state, state: LoadingState.error, error: action.error })),
  on(authActions.signedOut, () => ({ ...initialState }))
);
