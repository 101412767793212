import { ActionReducer } from "@ngrx/store";
import { storeLogger } from "@state/store-logger";
import { LoggerOptions } from "@state/store-logger/logger-options";
import { localStorageSync } from "ngrx-store-localstorage";
import { LoadingState, State } from "./models";

export const debug = (reducer: ActionReducer<State>): ActionReducer<State> => {
  const options: LoggerOptions = {
    collapsed: true,
    timestamp: false
  };
  return storeLogger(options)(reducer);
};

export const localStorageSyncReducer = (reducer: ActionReducer<State>): ActionReducer<State> => localStorageSync({
  keys: ["auth", "user", "account", "feature", "termAsset", "selectedAsset"],
  rehydrate: true,
  storage: localStorage,
  syncCondition: (state: State) =>
    state.auth.state !== LoadingState.error &&
    state.auth.state !== LoadingState.loading
})(reducer);
