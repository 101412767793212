import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { NgbDropdownModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { LanguageService } from "@services/support/language.service";
import { ClickOutsideModule } from "ng-click-outside";
import { SimplebarAngularModule } from "simplebar-angular";
import { FooterComponent } from "./footer/footer.component";
import { HorizontalComponent } from "./horizontal/horizontal.component";
import { HorizontaltopbarComponent } from "./horizontaltopbar/horizontaltopbar.component";
import { LayoutComponent } from "./layout.component";
import { RightsidebarAssetComponent } from "./rightsidebar-asset/rightsidebar-asset.component";
import { RightsidebarComponent } from "./rightsidebar/rightsidebar.component";
import { SharingLayoutComponent } from "./sharing/sharing-layout.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { TopbarComponent } from "./topbar/topbar.component";
import { VerticalComponent } from "./vertical/vertical.component";

@NgModule({
  declarations: [LayoutComponent, SharingLayoutComponent, SidebarComponent, TopbarComponent, FooterComponent, RightsidebarComponent, RightsidebarAssetComponent, HorizontalComponent, VerticalComponent, HorizontaltopbarComponent],
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    NgbDropdownModule,
    ClickOutsideModule,
    SimplebarAngularModule
  ],
  providers: [LanguageService]
})
export class LayoutsModule { }
