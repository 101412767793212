import { createReducer, on } from "@ngrx/store";
import authActions from "@state/auth/actions";
import { LoadingState } from "@state/models";
import actions from "./actions";
import { QueueCountState } from "./models";

const initialState: QueueCountState = {
  count: 0,
  state: LoadingState.init,
  error: null
};

export const queueCountReducer = createReducer(
  initialState,
  on(actions.queueCountLoading, (state) => ({ ...state, state: LoadingState.loading, error: null })),
  on(actions.queueCountLoaded, (state, action) => ({ ...state, count: action.count, state: LoadingState.loaded, error: null })),
  on(actions.add, (state, action) => ({ ...state, count: state.count + action.count })),
  on(actions.remove, (state, action) => ({ ...state, count: state.count - action.count })),
  on(actions.reset, () => ({ ...initialState })),
  on(actions.error, (state, action) => ({ ...state, state: LoadingState.error, error: action.error })),
  on(authActions.signedOut, () => ({ ...initialState }))
);
