import { DOCUMENT } from "@angular/common";
import { AfterViewInit, Component, ElementRef, Inject, Input, OnChanges, OnInit, ViewChild } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import "@helpers/array-extensions";
import { PermissionType } from "@models/auth/permission-type";
import { MenuItem, getMenu } from "@models/menu";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store, select } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { AccountService } from "@services/account/account.service";
import { DownloadQueueService } from "@services/download-queue/download-queue.service";
import { EventService } from "@services/support/event.service";
import { Logger } from "@services/support/logger.service";
import accountSelectors from "@state/account/selectors";
import featureSelectors from "@state/features/selectors";
import imageProfileActions from "@state/image-profile/actions";
import { ImageProfileItemState } from "@state/image-profile/models";
import { State } from "@state/models";
import queueActions from "@state/queue/actions";
import queueSelectors from "@state/queue/selectors";
import releaseNoteSelectors from "@state/release-notes/selectors";
import selectedAssetActions from "@state/selected-asset/actions";
import userSelectors from "@state/user/selectors";
import MetisMenu from "metismenujs";
import { firstValueFrom } from "rxjs";
import { take } from "rxjs/operators";

@UntilDestroy()
@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"]
})
export class SidebarComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild("sideMenu") sideMenu: ElementRef;
  @ViewChild("componentRef") scrollRef: any;
  @Input() isCondensed = false;
  account$ = this.store.select(accountSelectors.currentAccount);
  queueCount$ = this.store.select(queueSelectors.count);
  releaseNote$ = this.store.select(releaseNoteSelectors.hasNewRelease);
  currentAccountId: number;

  menu: any;
  data: any;
  menuItems: MenuItem[] = [];

  constructor (
    @Inject(DOCUMENT) private document: any,
    private logger: Logger,
    private store: Store<State>,
    private eventService: EventService,
    public translate: TranslateService,
    private accountService: AccountService,
    private downloadQueueService: DownloadQueueService,
    private router: Router) {
    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this.activateMenuDropdown();
        this.scrollElement();
      }
    });
  }

  async ngOnInit () {
    this.initialize();
    this.loadQueueCount();
    this.loadSelectedAssets();
    this.document.body.setAttribute("data-sidebar", "dark");

    this.scrollElement();
  }

  changeLayout (layout: string) {
    this.eventService.broadcast("changeLayout", layout);
  }

  ngAfterViewInit () {
    this.menu = new MetisMenu(this.sideMenu.nativeElement);
    setTimeout(() => {
      this.activateMenuDropdown();
    }, 500);
  }

  toggleMenu (event: any) {
    event.currentTarget.nextElementSibling.classList.toggle("mm-show");
  }

  ngOnChanges () {
    if (!this.isCondensed && this.sideMenu || this.isCondensed) {
      setTimeout(() => {
        this.menu = new MetisMenu(this.sideMenu.nativeElement);
      });
    } else if (this.menu) {
      this.menu.dispose();
    }
  }

  scrollElement () {
    setTimeout(() => {
      if (this.document.getElementsByClassName("mm-active").length > 0) {
        const currentPosition = this.document.getElementsByClassName("mm-active")[0].offsetTop;
        if (currentPosition > 500) {
          if (this.scrollRef.SimpleBar.getScrollElement() !== null) {
            this.scrollRef.SimpleBar.getScrollElement().scrollTop =
              currentPosition + 300;
          }
        }
      }
    }, 300);
  }

  removeAllClass (className: string) {
    const els = this.document.getElementsByClassName(className);
    while (els[0]) {
      els[0].classList.remove(className);
    }
  }

  activateMenuDropdown () {
    this.removeAllClass("mm-active");
    this.removeAllClass("mm-show");

    const links = this.document.getElementsByClassName("side-nav-link-ref");
    let menuItemEl: any = null;
    const paths: any[] = [];

    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < links.length; i++) {
      paths.push(links[i].pathname);
    }

    menuItemEl = this.getMenuElement(paths, links, window.location.pathname);

    if (menuItemEl) {
      menuItemEl.classList.add("active");
      const parentEl = menuItemEl.parentElement;
      if (parentEl) {
        parentEl.classList.add("mm-active");
        const parent2El = parentEl.parentElement.closest("ul");

        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.add("mm-show");
          const parent3El = parent2El.parentElement;

          if (parent3El && parent3El.id !== "side-menu") {
            parent3El.classList.add("mm-active");
            const childAnchor = parent3El.querySelector(".has-arrow");
            const childDropdown = parent3El.querySelector(".has-dropdown");

            if (childAnchor) {
              childAnchor.classList.add("mm-active");
            }
            if (childDropdown) {
              childDropdown.classList.add("mm-active");
            }

            const parent4El = parent3El.parentElement;

            if (parent4El && parent4El.id !== "side-menu") {
              parent4El.classList.add("mm-show");
              const parent5El = parent4El.parentElement;

              if (parent5El && parent5El.id !== "side-menu") {
                parent5El.classList.add("mm-active");
                const childanchor = parent5El.querySelector(".is-parent");
                if (childanchor && parent5El.id !== "side-menu") {
                  childanchor.classList.add("mm-active");
                }
              }
            }
          }
        }
      }
    }
  }

  getMenuElement (paths: any[], links: any, pathname: string): any {
    const itemIndex = paths.indexOf(pathname);

    if (itemIndex > -1) {
      //encontrou a url exata
      return links[itemIndex];
    }
    else {
      //volta uma barra
      const slashIndex = pathname.lastIndexOf("/");

      if (slashIndex > -1) {
        const path = pathname.substring(0, slashIndex);
        const pathIndex = paths.indexOf(path);

        if (pathIndex > -1) {
          return links[pathIndex];
        } else {
          return this.getMenuElement(paths, links, path);
        }
      } else {
        return null;
      }
    }
  }

  initialize (): void {
    this.account$.pipe(untilDestroyed(this)).subscribe(async account => {
      if (account && account.accountId !== this.currentAccountId) {
        this.currentAccountId = account.accountId;

        try {
          this.store.dispatch(imageProfileActions.imageProfileLoading());
          const imageProfiles = await this.accountService.getImageProfiles(this.currentAccountId);
          const items = imageProfiles.map<ImageProfileItemState>(x => ({ name: x.name!, suffix: x.suffix!, scale: x.scale!, maxSize: x.maxSize!, maxFileSize: x.maxFileSize }));
          this.store.dispatch(imageProfileActions.imageProfileLoaded({ items }));
        }
        catch (error: any) {
          this.store.dispatch(imageProfileActions.error({ error }));
        }

        try {
          const features = await firstValueFrom(this.store.pipe(select(featureSelectors.features), take(1)));
          const user = await firstValueFrom(this.store.pipe(select(userSelectors.user), take(1)));
          this.menuItems = getMenu(features, (user.role as PermissionType), user.isAdmMaster!);

          this.queueCount$.pipe(untilDestroyed(this)).subscribe(count => {
            const queueMenu = this.menuItems.findR((x: MenuItem) => x.id === 2050, "subItems");
            queueMenu.badge.show = count > 0;
            queueMenu.badge.text = String(count).padStart(2, "0");
          });

          this.releaseNote$.pipe(untilDestroyed(this)).subscribe(hasNewRelease => {
            const releaseMenu = this.menuItems.findR((x: MenuItem) => x.id === 70, "subItems");
            releaseMenu.badge.show = hasNewRelease;
          });
        }
        catch (err: any) {
          this.logger.error(err);
          this.menuItems = getMenu({}, PermissionType.None, false);
        }
        setTimeout(() => this.menu.update(), 100);
      }
    });
  }

  hasItems (item: MenuItem) {
    return item.subItems !== undefined
      ? item.subItems.length > 0 && item.subItems.some((i: any) => i.show)
      : false;
  }

  private async loadQueueCount () {
    this.store.dispatch(queueActions.queueCountLoading());
    const current = await this.downloadQueueService.getCurrentQueue();
    this.store.dispatch(
      queueActions.queueCountLoaded({ count: current.items.length })
    );
  }

  private async loadSelectedAssets () {
    this.store.dispatch(selectedAssetActions.selectedAssetLoading());
    this.store.dispatch(selectedAssetActions.selectedAssetLoaded());
  }
}
