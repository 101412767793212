import { createSelector } from "@ngrx/store";
import { LoadingState, State } from "@state/models";
import { UserState } from "./models";

const slice = (state: State) => state.user;

export const isLoading = createSelector(slice,
  (user: UserState) => user.state === LoadingState.loading
);

export const isLoaded = createSelector(slice,
  (user: UserState) => user.state === LoadingState.loaded
);

export const error = createSelector(slice,
  (user: UserState) => user.error
);

export const user = createSelector(slice,
  (userState: UserState) => userState
);

export const userId = createSelector(slice,
  (userState: UserState) => userState.userId
);

export const termOfUseAccepted = createSelector(slice,
  (userState: UserState) => userState.termOfUseAccepted
);

export default {
  isLoading,
  isLoaded,
  error,
  user,
  userId,
  termOfUseAccepted
};
