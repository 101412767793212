import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@environment";
import { ResetData } from "@models/auth/reset-data";
import { AuthState } from "@state/auth/models";
import { firstValueFrom } from "rxjs";
import { tap } from "rxjs/operators";
import { Logger } from "../support/logger.service";

@Injectable({
  providedIn: "root"
})
export class AuthService {
  constructor (
    private logger: Logger,
    private http: HttpClient) {
  }

  login (username: string, password: string): Promise<AuthState> {
    return firstValueFrom(
      this.http.post<AuthState>(`${environment.identity.url}/token`, {
        username,
        password
      }).pipe(tap({
        next: () => undefined,
        error: err => this.logger.error(err)
      }))
    );
  }

  loginSso (token: string): Promise<AuthState> {
    return firstValueFrom(
      this.http.post<AuthState>(`${environment.identity.url}/sso/token`, {
        token
      }).pipe(tap({
        next: () => undefined,
        error: err => this.logger.error(err)
      }))
    );
  }

  loginSsoCode (code: string): Promise<AuthState> {
    return firstValueFrom(
      this.http.post<AuthState>(`${environment.identity.url}/sso/token/code`, {
        domain: location.hostname,
        code
      }).pipe(tap({
        next: () => undefined,
        error: err => this.logger.error(err)
      }))
    );
  }

  refreshToken (): Promise<AuthState> {
    return firstValueFrom(
      this.http.post<AuthState>(`${environment.identity.url}/token/refresh`, {}).pipe(tap({
        next: () => undefined,
        error: err => this.logger.error(err)
      }))
    );
  }

  reset (username: string): Promise<unknown> {
    return firstValueFrom(
      this.http.post(`${environment.identity.url}/reset`, {
        username,
        domain: location.origin
      }).pipe(tap({
        next: () => undefined,
        error: err => this.logger.error(err)
      }))
    );
  }

  resetChange (key: string, password: string, passwordConfirm: string): Promise<unknown> {
    return firstValueFrom(
      this.http.post(`${environment.identity.url}/reset/change`, {
        key,
        password,
        passwordConfirm
      }).pipe(tap({
        next: () => undefined,
        error: err => this.logger.error(err)
      }))
    );
  }

  getResetData (key: string): Promise<ResetData> {
    return firstValueFrom(
      this.http.get<ResetData>(`${environment.identity.url}/reset/data?key=${key}`).pipe(tap({
        next: () => undefined,
        error: err => this.logger.error(err)
      }))
    );
  }

  changePassword (currentPassword: string, newPassword: string, confirmPassword: string): Promise<unknown> {
    return firstValueFrom(
      this.http.post(`${environment.identity.url}/password/change`, {
        currentPassword,
        newPassword,
        confirmPassword
      }).pipe(tap({
        next: () => undefined,
        error: err => this.logger.error(err)
      }))
    );
  }
}
