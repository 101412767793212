<div class="right-bar-asset" (clickOutside)="hide($event)" [exclude]="'.right-bar-asset-toggle,i'">
  <ngx-simplebar class="h-100">
    <div class="rightbar-title px-3 py-4">
      <a href="javascript:void(0);" class="right-bar-asset-toggle float-end" (click)="hide($event)">
        <i class="mdi mdi-close noti-icon"></i>
      </a>
      <h5 class="m-0" translate>general.assets</h5>
    </div>
    <hr class="my-0">
    <div class="p-2">
      <div class="text-center" *ngIf="loading">{{ "general.loading" | translate }}</div>
      <div class="text-center" *ngIf="!loading && assets.length === 0">{{ "general.noRecords" | translate }}</div>
      <div *ngIf="!loading && assets.length > 0">
        <table class="table table-nowrap align-middle mb-0">
          <tbody>
            <tr *ngFor="let asset of assets">
              <td class="align-top">
                <div class="avatar-md" [ngClass]="{ 'h-auto': asset.url }">
                  <img #img [src]="getUrl(asset)" alt="" class="d-block img-fluid height m-auto" (error)="onImgError($event)">
                </div>
              </td>
              <td class="ps-0 w-100 align-top">
                <h5 class="font-size-14 mb-0 text-wrap text-break">{{ asset.name }}</h5>
                <small class="d-block text-wrap text-break">{{ asset.category }}</small>
                <small class="d-block text-wrap text-break" *ngIf="asset.kit"><i>Kit: {{ asset.kit }}</i></small>
              </td>
              <td class="align-top">
                <i class="bx bx-trash text-danger pointer" [title]="'general.delete' | translate" (click)="remove(asset.id)"></i>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="mt-2 d-flex justify-content-end">
          <div class="btn-group" ngbDropdown>
            <button type="button" ngbDropdownToggle class="btn btn-primary me-1">Ações <i class="mdi mdi-chevron-down"></i></button>
            <div ngbDropdownMenu class="dropdown-menu">
              <button ngbDropdownItem (click)="goToNewKitPage('move')">{{ "general.moveToNewKit" | translate }}</button>
              <button ngbDropdownItem (click)="goToSelectKitPage('move')">{{ "general.moveToExistingKit" | translate }}</button>
              <div class="dropdown-divider"></div>
              <button ngbDropdownItem (click)="goToNewKitPage('copy')">{{ "general.copyToNewKit" | translate }}</button>
              <button ngbDropdownItem (click)="goToSelectKitPage('copy')">{{ "general.copyToExistingKit" | translate }}</button>
            </div>
          </div>
          <button type="button" class="btn btn-danger" (click)="removeAll()">{{ "general.removeAll" | translate }}</button>
        </div>
      </div>
    </div>
  </ngx-simplebar>
</div>

<div class="rightbar-asset-overlay"></div>