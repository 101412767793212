import { DOCUMENT } from "@angular/common";
import { AfterViewInit, Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { PermissionType } from "@models/auth/permission-type";
import { getMenu, MenuItem } from "@models/menu";
import { Notification } from "@models/support/notification";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { select, Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { AccountService } from "@services/account/account.service";
import { ConfigService } from "@services/support/config.service";
import { EventService } from "@services/support/event.service";
import { InsightsService } from "@services/support/insights.service";
import { LanguageService } from "@services/support/language.service";
import { Logger } from "@services/support/logger.service";
import { NotificationService } from "@services/support/notification.service";
import accountSelectors from "@state/account/selectors";
import authActions from "@state/auth/actions";
import featureSelectors from "@state/features/selectors";
import imageProfileActions from "@state/image-profile/actions";
import { ImageProfileItemState } from "@state/image-profile/models";
import { State } from "@state/models";
import releaseNoteActions from "@state/release-notes/actions";
import selectedAssetSelectors from "@state/selected-asset/selectors";
import { UserState } from "@state/user/models";
import userSelectors from "@state/user/selectors";
import { CookieService } from "ngx-cookie-service";
import { ToastrService } from "ngx-toastr";
import { firstValueFrom, Subscription } from "rxjs";
import { take } from "rxjs/operators";

@UntilDestroy()
@Component({
  selector: "app-horizontal-topbar",
  templateUrl: "./horizontaltopbar.component.html",
  styleUrls: ["./horizontaltopbar.component.scss"]
})
export class HorizontaltopbarComponent implements OnInit, OnDestroy, AfterViewInit {
  @Output() settingsButtonClicked = new EventEmitter();
  @Output() assetsButtonClicked = new EventEmitter();
  element: any;
  flagvalue: any;
  cookieValue = "";
  countryName: any;
  valueset = "";
  menuItems: MenuItem[] = [];
  notifications: Notification[] = [];
  unreadNotificationsCount = 0;
  user$ = this.store.select(userSelectors.user);
  accounts$ = this.store.select(accountSelectors.accounts);
  account$ = this.store.select(accountSelectors.currentAccount);
  fileIds$ = this.store.select(selectedAssetSelectors.fileIds);
  assetSelectionRoles = [PermissionType.Administrador, PermissionType.AdministradorBasic, PermissionType.Normal, PermissionType.NormalGrupos, PermissionType.BasicoPlus];
  currentAccountId: number;
  currentAccountName: string;
  subs: Subscription;
  colors = ["bg-danger", "bg-info", "bg-pink", "primary", "bg-secondary", "bg-success", "bg-warning"];
  randomColor: string;

  image = {
    bigLogo: this.configService.getUrl("logo"),
    smallLogo: this.configService.getUrl("logo-mobile")
  };

  listLang = [
    { text: "Português", flag: "assets/images/flags/brazil.jpg", lang: "pt-BR" },
    { text: "English", flag: "assets/images/flags/us.jpg", lang: "en-US" },
    { text: "Español", flag: "assets/images/flags/spain.jpg", lang: "es-ES" }
  ];

  constructor (
    @Inject(DOCUMENT) private document: any,
    private router: Router,
    private logger: Logger,
    private insightsService: InsightsService,
    private store: Store<State>,
    private configService: ConfigService,
    private eventService: EventService,
    private notifService: NotificationService,
    private accountService: AccountService,
    private toastr: ToastrService,
    public translate: TranslateService,
    public languageService: LanguageService,
    private cookiesService: CookieService) {
    router.events.pipe(untilDestroyed(this)).subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.activateMenu();
      }
    });
  }

  getNotifUrl (notif: Notification) {
    switch (notif.area) {
      case "release":
        return "/release-notes";
      case "download-queue":
        return "/cm/download-queue";
      default:
        return undefined;
    }
  }

  async ngOnInit () {
    this.element = this.document.documentElement;
    this.initialize();

    this.cookieValue = this.cookiesService.get("lang");
    const val = this.listLang.filter(x => x.lang === this.cookieValue);
    this.countryName = val.map(element => element.text);

    if (val.length === 0) {
      if (this.flagvalue === undefined) {
        this.valueset = "assets/images/flags/us.jpg";
      }
    } else {
      this.flagvalue = val.map(element => element.flag);
    }

    const downloadQueueTitle = await firstValueFrom(this.translate.get("alerts.processedQueue.title"));
    const downloadQueueText = await firstValueFrom(this.translate.get("alerts.processedQueue.text"));

    this.subs = this.eventService.subscribe("load_notifications", (notifications: Notification[]) => {
      const currentUnreadNotifications = this.notifications.filter(x => !x.isRead);
      const newUnreadNotifications = notifications.filter(x => !x.isRead);

      this.unreadNotificationsCount = newUnreadNotifications.length;
      this.notifications = notifications;

      const temp = currentUnreadNotifications.map(x => JSON.stringify(x));
      const except = newUnreadNotifications.filter(x => !temp.includes(JSON.stringify(x)));

      if (except.some(n => n.area === "release")) {
        this.store.dispatch(releaseNoteActions.setNewReleaseNote(true));
      }

      if (except.some(n => n.area === "download-queue")) {
        this.toastr.success(downloadQueueText, downloadQueueTitle);
      }

      this.randomColor = this.colors[this.rnd(0, 6)];
    });
  }

  ngOnDestroy (): void {
    if (this.subs) {
      this.subs.unsubscribe();
    }
  }

  rnd (min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  hasAssetSelectButton (user: UserState | null) {
    return user && this.assetSelectionRoles.some(role => user.role === role);
  }

  async notificationClicked (isOpened: boolean) {
    if (isOpened && this.unreadNotificationsCount > 0) {
      try {
        await this.notifService.markAsRead();
        this.unreadNotificationsCount = 0;
      }
      catch { }
    }
  }

  setLanguage (text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.languageService.setLanguage(lang);
  }

  logout () {
    localStorage.clear();
    sessionStorage.clear();
    this.cookiesService.delete("sessionId", "/");
    this.store.dispatch(authActions.signedOut());
    this.insightsService.clearUserId();
    this.logger.info("user signedout... redirecting to login page");
    this.router.navigate(["/login"]);
  }

  onMenuClick (event: any) {
    const nextEl = event.target.nextElementSibling;

    if (nextEl) {
      const parentEl = event.target.parentNode;
      if (parentEl) {
        parentEl.classList.remove("show");
      }
      nextEl.classList.toggle("show");
    }

    return false;
  }

  ngAfterViewInit () {
    this.activateMenu();
  }

  removeAllClass (className: string) {
    const els = this.document.getElementsByClassName(className);

    while (els[0]) {
      els[0].classList.remove(className);
    }
  }

  toggleMenubar () {
    const element = this.document.getElementById("topnav-menu-content");
    element?.classList.toggle("show");
  }

  activateMenu () {
    const resetParent = (el: any) => {
      const parent = el.parentElement;

      if (parent) {
        parent.classList.remove("active");
        this.removeAllClass("mm-active");
        this.removeAllClass("mm-show");
        const parent2 = parent.parentElement;

        if (parent2) {
          parent2.classList.remove("active");
          const parent3 = parent2.parentElement;

          if (parent3) {
            parent3.classList.remove("active");
            const parent4 = parent3.parentElement;

            if (parent4) {
              parent4.classList.remove("active");
              const parent5 = parent4.parentElement;

              if (parent5) {
                parent5.classList.remove("active");
                const menuelement = this.document.getElementById("topnav-menu-content");

                if (menuelement !== null) {
                  if (menuelement.classList.contains("show")) {
                    this.document.getElementById("topnav-menu-content")?.classList.remove("show");
                  }
                }
              }
            }
          }
        }
      }
    };

    // activate menu item based on location
    const links = this.document.getElementsByClassName("side-nav-link-ref");
    let matchingMenuItem: any = null;

    for (const link of links) {
      resetParent(link);
    }

    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < links.length; i++) {
      if (location.pathname === links[i].pathname) {
        matchingMenuItem = links[i];
        break;
      }
    }

    if (matchingMenuItem) {
      const parent = matchingMenuItem.parentElement;
      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.add("active");
        const parent2 = parent.parentElement;

        if (parent2) {
          parent2.classList.add("active");
          const parent3 = parent2.parentElement;

          if (parent3) {
            parent3.classList.add("active");
            const parent4 = parent3.parentElement;

            if (parent4) {
              parent4.classList.add("active");
              const parent5 = parent4.parentElement;

              if (parent5) {
                parent5.classList.add("active");
              }
            }
          }
        }
      }
    }
  }

  onSettingsButtonClicked () {
    this.settingsButtonClicked.emit();
  }

  onAssetsButtonClicked () {
    this.assetsButtonClicked.emit();
  }

  fullscreen () {
    this.document.body.classList.toggle("fullscreen-enable");

    if (!this.document.fullscreenElement && !this.element.mozFullScreenElement && !this.element.webkitFullscreenElement) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        this.element.mozRequestFullScreen();
      } else if (this.element.webkitRequestFullscreen) {
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) {
        this.element.msRequestFullscreen();
      }
    }
    else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        this.document.msExitFullscreen();
      }
    }
  }

  topbarLight () {
    document.body.setAttribute("data-topbar", "light");
    document.body.removeAttribute("data-layout-size");
    document.body.removeAttribute("data-layout-scrollable");
  }

  boxedWidth () {
    document.body.setAttribute("data-layout-size", "boxed");
    document.body.setAttribute("data-topbar", "dark");
    document.body.removeAttribute("data-layout-scrollable");
  }

  coloredHeader () {
    document.body.setAttribute("data-topbar", "colored");
    document.body.removeAttribute("data-layout-size");
    document.body.removeAttribute("data-layout-scrollable");
  }

  scrollable () {
    document.body.removeAttribute("data-layout-size");
    document.body.setAttribute("data-layout-scrollable", "true");
  }

  changeLayout (layout: string) {
    this.eventService.broadcast("changeLayout", layout);
  }

  initialize (): void {
    this.configService.bigLogoChanged.pipe(untilDestroyed(this)).subscribe(url => this.image.bigLogo = url);
    this.configService.smallLogoChanged.pipe(untilDestroyed(this)).subscribe(url => this.image.smallLogo = url);

    this.account$.pipe(untilDestroyed(this)).subscribe(async account => {
      if (account && account.accountId !== this.currentAccountId) {
        this.currentAccountId = account.accountId;
        this.currentAccountName = account.name;

        try {
          this.store.dispatch(imageProfileActions.imageProfileLoading());
          const imageProfiles = await this.accountService.getImageProfiles(this.currentAccountId);
          const items = imageProfiles.map<ImageProfileItemState>(x => ({ name: x.name!, suffix: x.suffix!, scale: x.scale!, maxSize: x.maxSize!, maxFileSize: x.maxFileSize }));
          this.store.dispatch(imageProfileActions.imageProfileLoaded({ items }));
        }
        catch(error: any) {
          this.store.dispatch(imageProfileActions.error({ error }));
        }

        try {
          const features = await firstValueFrom(this.store.pipe(select(featureSelectors.features), take(1)));
          const user = await firstValueFrom(this.store.pipe(select(userSelectors.user), take(1)));
          this.menuItems = getMenu(features, (user.role as PermissionType), user.isAdmMaster!).filter(v => !v.isTitle);
        }
        catch (err: any) {
          this.logger.error(err);
          this.menuItems = getMenu({}, PermissionType.None, false).filter(v => !v.isTitle);
        }
      }
    });
  }

  hasItems (item: MenuItem) {
    return item.subItems !== undefined
      ? item.subItems.length > 0 && item.subItems.some((i: any) => i.show)
      : false;
  }
}
