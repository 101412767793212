<ng-template #contentTemplate>
  <div id="sidebar-menu">
    <ul class="metismenu list-unstyled" id="side-menu" #sideMenu>
      <ng-container *ngFor="let item of menuItems">
        <li class="menu-title" *ngIf="item.isTitle && !item.isLayout && item.show">{{ item.label | translate }}</li>
        <li *ngIf="item.isLayout">
          <a href="javascript:void(0);" class="has-arrow">
            <i class="bx bx-layout"></i>
            <span> {{'menuitems.layouts.text' | translate}}</span>
          </a>
          <ul class="sub-menu" aria-expanded="false">
            <li><a (click)="changeLayout('horizontal')" href="javascript:void(0);">{{ 'menuitems.layouts.list.horizontal' | translate }} </a></li>
          </ul>
        </li>

        <li *ngIf="!item.isTitle && !item.isLayout && item.show">
          <a *ngIf="hasItems(item)" href="javascript:void(0);" class="is-parent" [ngClass]="{'has-arrow': (!item.badge || item.badge && item.badge.show), 'has-dropdown': item.badge && item.badge.show}">
            <i class="{{ item.icon }}" *ngIf="item.icon"></i>
            <span> {{ item.label | translate }}</span>
            <span class="badge rounded-pill float-end bg-{{item.badge.variant}} {{item.badge.class}}" *ngIf="item.badge && item.badge.show">
              <ng-container *ngIf="item.badge.text">{{ item.badge.text | translate }}</ng-container>
              <ng-container *ngIf="item.badge.icon"><i [class]="'icon ' + item.badge.icon"></i></ng-container>
            </span>
          </a>

          <a [routerLink]="item.link" *ngIf="!hasItems(item) && item.show" class="side-nav-link-ref" routerLinkActive="active">
            <i class="{{ item.icon }}" *ngIf="item.icon"></i>
            <span> {{ item.label | translate }}</span>
            <span class="badge rounded-pill bg-{{item.badge.variant}} float-end {{item.badge.class}}" *ngIf="item.badge && item.badge.show">
              <ng-container *ngIf="item.badge.text">{{ item.badge.text | translate }}</ng-container>
              <ng-container *ngIf="item.badge.icon"><i [class]="'icon ' + item.badge.icon"></i></ng-container>
            </span>
          </a>

          <ul *ngIf="hasItems(item)" class="sub-menu" aria-expanded="false">
            <li *ngFor="let subitem of item.subItems">
              <a [routerLink]="subitem.link" *ngIf="!hasItems(subitem) && subitem.show" class="side-nav-link-ref" [attr.data-parent]="subitem.parentId" routerLinkActive="active">
                <i class="{{ subitem.icon }}" *ngIf="subitem.icon"></i>
                <span>{{ subitem.label | translate }}</span>
                <span class="badge rounded-pill bg-{{subitem.badge.variant}} float-end {{subitem.badge.class}}" *ngIf="subitem.badge && subitem.badge.show">
                  <ng-container *ngIf="subitem.badge.text">{{ subitem.badge.text | translate }}</ng-container>
                  <ng-container *ngIf="subitem.badge.icon"><i [class]="'icon ' + subitem.badge.icon"></i></ng-container>
                </span>
              </a>
              <a *ngIf="hasItems(subitem) && subitem.show" class="side-nav-link-a-ref has-arrow" href="javascript:void(0);" [attr.data-parent]="subitem.parentId">
                <i class="{{ subitem.icon }}" *ngIf="subitem.icon"></i>
                <span>{{ subitem.label | translate }}</span>
                <span class="badge rounded-pill bg-{{subitem.badge.variant}} float-end {{subitem.badge.class}}" *ngIf="subitem.badge && subitem.badge.show">
                  <ng-container *ngIf="subitem.badge.text">{{ subitem.badge.text | translate }}</ng-container>
                  <ng-container *ngIf="subitem.badge.icon"><i [class]="'icon ' + subitem.badge.icon"></i></ng-container>
                </span>
              </a>
              <ul *ngIf="hasItems(subitem)" class="sub-menu mm-collapse" aria-expanded="false">
                <li *ngFor="let subSubitem of subitem.subItems">
                  <a *ngIf="subSubitem.show" [attr.data-parent]="subSubitem.parentId" [routerLink]="subSubitem.link" routerLinkActive="active" class="side-nav-link-ref">
                    <i class="{{ subSubitem.icon }}" *ngIf="subSubitem.icon"></i>
                    <span>{{ subSubitem.label | translate }}</span>
                    <span class="badge rounded-pill bg-{{subSubitem.badge.variant}} float-end {{subSubitem.badge.class}}" *ngIf="subSubitem.badge && subSubitem.badge.show">
                      <ng-container *ngIf="subSubitem.badge.text">{{ subSubitem.badge.text | translate }}</ng-container>
                      <ng-container *ngIf="subSubitem.badge.icon"><i [class]="'icon ' + subSubitem.badge.icon"></i></ng-container>
                    </span>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ng-container>
    </ul>
  </div>
</ng-template>

<div class="vertical-menu">
  <ngx-simplebar class="hddd-100" #componentRef>
    <div *ngIf="!isCondensed">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
    <div *ngIf="isCondensed">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
  </ngx-simplebar>
</div>
