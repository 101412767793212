import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  private isInitialized = false;

  constructor(private router: Router) {}

  public initializeAnalytics(measurementId: string): void {
    if (!this.isInitialized && measurementId) {
      this.isInitialized = true;

      // Inicializar o Google Analytics com a ID de medição fornecida
      gtag('js', new Date());
      gtag('config', measurementId);

      this.onRouteChange(measurementId);
    }
  }

  private onRouteChange(measurementId: string) {    
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        gtag('config', measurementId, {
          page_path: event.urlAfterRedirects,
        });
      }
    });
  }
}
