import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AbortSignal } from "@azure/abort-controller";
import { TransferProgressEvent } from "@azure/core-http";
import { AnonymousCredential, BlobServiceClient, ContainerClient, Metadata } from "@azure/storage-blob";
import { NgxPicaService } from "@digitalascetic/ngx-pica";
import { environment } from "@environment";
import { AssetSelected } from "@models/asset/asset-selected";
import { ItemGalleryResult } from "@models/user-gallery/item-gallery-result";
import { Store, select } from "@ngrx/store";
import { ExtensionService } from "@services/support/extension.service";
import imageProfileSelectors from "@state/image-profile/selectors";
import { State } from "@state/models";
import { firstValueFrom } from "rxjs";
import { take, tap } from "rxjs/operators";
import { Logger } from "../support/logger.service";

@Injectable({
  providedIn: "root"
})
export class AssetService {
  constructor (
    private logger: Logger,
    private store: Store<State>,
    private extensionService: ExtensionService,
    private picaService: NgxPicaService,
    private http: HttpClient) {
  }

  getAssets (): Promise<{ url: string; pinned: boolean; size: string; filesize: number }[]> {
    return firstValueFrom(
      this.http.get<{ url: string; pinned: boolean; size: string; filesize: number }[]>(`${environment.catalog.url}/assets`).pipe(tap({
        next: () => undefined,
        error: err => this.logger.error(err)
      }))
    );
  }

  getSelectedFiles (fileIds: number[]): Promise<AssetSelected[]> {
    return firstValueFrom(
      this.http.post<AssetSelected[]>(`${environment.catalog.url}/files/details`, {
        fileIds
      }).pipe(tap({
        next: () => undefined,
        error: err => this.logger.error(err)
      }))
    );
  }

  moveSelectedFiles (fileIds: number[], itemId: number): Promise<unknown> {
    return firstValueFrom(
      this.http.post(`${environment.catalog.url}/files/move-to-kit`, {
        fileIds,
        itemId
      }).pipe(tap({
        next: () => undefined,
        error: err => this.logger.error(err)
      }))
    );
  }

  copySelectedFiles (fileIds: number[], itemId: number): Promise<unknown> {
    return firstValueFrom(
      this.http.post(`${environment.catalog.url}/files/copy-to-kit`, {
        fileIds,
        itemId
      }).pipe(tap({
        next: () => undefined,
        error: err => this.logger.error(err)
      }))
    );
  }

  getGallery (asset: string): Promise<ItemGalleryResult[]> {
    return firstValueFrom(
      this.http.get<ItemGalleryResult[]>(`${environment.catalog.url}/assets/${asset}/gallery`).pipe(tap({
        next: () => undefined,
        error: err => this.logger.error(err)
      }))
    );
  }

  getSasToken (): Promise<{ token: string }> {
    return firstValueFrom(
      this.http.get<{ token: string }>(`${environment.catalog.url}/assets/sas`).pipe(tap({
        next: () => undefined,
        error: err => this.logger.error(err)
      }))
    );
  }

  deleteAsset (name: string, folder: string): Promise<unknown> {
    return firstValueFrom(
      this.http.delete(`${environment.catalog.url}/${folder}/${name}`).pipe(tap({
        next: () => undefined,
        error: err => this.logger.error(err)
      }))
    );
  }

  deleteGalleryItem (name: string, type: string): Promise<unknown> {
    return firstValueFrom(
      this.http.delete(`${environment.catalog.url}/assets/gallery/${name}/${type}`).pipe(tap({
        next: () => undefined,
        error: err => this.logger.error(err)
      }))
    );
  }

  async uploadGallery (token: string, file: File, filename: string, userId: number, abortSignal: AbortSignal, folder: string = "") {
    const blobServiceClient = new BlobServiceClient(`${environment.storageUrl}?${token}`, new AnonymousCredential());
    const containerClient = blobServiceClient.getContainerClient("user");
    const blobClient = containerClient.getBlockBlobClient(`${userId}${folder}/gallery/${filename}`);

    let galleryFile: any = await this.resizeImage(file, { width: 400, height: 400 });

    let response: any = await blobClient.uploadData(galleryFile, {
      abortSignal,
      blockSize: 4 * 1024 * 1024,
      blobHTTPHeaders: { blobContentType: file.type }
    });

    if (response.errorCode !== undefined) {
      throw new Error(`uploadGalleryError: (${response.errorCode})`);
    }

    galleryFile = undefined;
    response = undefined;
  }

  async getMetadata (token: string, filename: string, userId: number, folder: string = "") {
    const blobServiceClient = new BlobServiceClient(`${environment.storageUrl}?${token}`, new AnonymousCredential());
    const containerClient = blobServiceClient.getContainerClient("user");
    const blobClient = containerClient.getBlockBlobClient(`${userId}${folder}/${filename}`);

    const response = await blobClient.getProperties();

    if (response.errorCode !== undefined) {
      throw new Error(`getMetadataError: (${response.errorCode})`);
    }

    return response.metadata;
  }

  async setMetadata (token: string, filename: string, userId: number, metadata: Metadata, folder: string = "") {
    const blobServiceClient = new BlobServiceClient(`${environment.storageUrl}?${token}`, new AnonymousCredential());
    const containerClient = blobServiceClient.getContainerClient("user");
    const blobClient = containerClient.getBlockBlobClient(`${userId}${folder}/${filename}`);

    let response: any = await blobClient.setMetadata(metadata);

    if (response.errorCode !== undefined) {
      throw new Error(`setMetadataError: (${response.errorCode})`);
    }

    response = undefined;
  }

  async uploadGalleryPermanent (token: string, itemId: number, file: File, filename: string, userId: number, abortSignal: AbortSignal): Promise<{ id: number; url: string }> {
    await this.uploadGallery(token, file, filename, userId, abortSignal);

    return firstValueFrom(
      this.http.post<{ id: number; url: string }>(`${environment.catalog.url}/assets/gallery/${itemId}/${filename}`, {}).pipe(tap({
        next: () => undefined,
        error: err => this.logger.error(err)
      }))
    );
  }

  updateCoverPosition (itemGalleryId: number | null, positionCount: number | null) {
    return firstValueFrom(
      this.http.put(`${environment.catalog.url}/assets/gallery/${itemGalleryId}/${positionCount ?? ""}`, {}).pipe(tap({
        next: () => undefined,
        error: err => this.logger.error(err)
      }))
    );
  }


  async uploadAssetResized (token: string, file: File, filename: string, userId: number, folder: string, abortSignal: AbortSignal) {
    const blobServiceClient = new BlobServiceClient(`${environment.storageUrl}?${token}`, new AnonymousCredential());
    const containerClient = blobServiceClient.getContainerClient("user");

    const p1: any = this.uploadResizedImage(containerClient, userId, folder, abortSignal, file, filename, "thumbnail", 1, 300, "thumbnail");
    const p2: any = this.uploadResizedImage(containerClient, userId, folder, abortSignal, file, filename, "r", 1, 1080, "regular");
    const p3: any = this.uploadResizedImage(containerClient, userId, folder, abortSignal, file, filename, "m", .5, 1080, "medium");
    const p4: any = this.uploadResizedImage(containerClient, userId, folder, abortSignal, file, filename, "s", .33333, 1080, "small");
    const p5: any = this.uploadResizedImage(containerClient, userId, folder, abortSignal, file, filename, "t", .25, 1080, "tiny");

    const threads = [p1, p2, p3, p4, p5];

    const imageProfiles = await firstValueFrom(this.store.pipe(select(imageProfileSelectors.imageProfiles), take(1)));

    if (imageProfiles) {
      imageProfiles.forEach(p =>
        threads.push(
          this.uploadResizedImage(containerClient, userId, folder, abortSignal, file, filename, p.suffix, p.scale, p.maxSize, p.name, p.maxFileSize)
        )
      );
    }

    let result: any = await Promise.all(threads);

    if (result.some((x: any) => x.errorCode)) {
      throw new Error("uploadAssetResizedError");
    }

    result = undefined;
  }

  async uploadAsset (token: string, file: File, filename: string, userId: number, abortSignal: AbortSignal, onProgress: any) {
    const blobServiceClient = new BlobServiceClient(`${environment.storageUrl}?${token}`, new AnonymousCredential());
    const containerClient = blobServiceClient.getContainerClient("user");
    const blobClient = containerClient.getBlockBlobClient(`${userId}/assets/${filename}`);

    const response = await blobClient.uploadData(file, {
      abortSignal,
      blockSize: 4 * 1024 * 1024,
      blobHTTPHeaders: { blobContentType: file.type },
      onProgress: (ev: TransferProgressEvent) => onProgress(this.calculateProgress(ev, file))
    });

    if (response.errorCode !== undefined) {
      throw new Error(`uploadAssetError: (${response.errorCode})`);
    }
  }

  async uploadAssetKit (token: string, file: File, filename: string, userId: number, abortSignal: AbortSignal, onProgress: any) {
    const blobServiceClient = new BlobServiceClient(`${environment.storageUrl}?${token}`, new AnonymousCredential());
    const containerClient = blobServiceClient.getContainerClient("user");
    const blobClient = containerClient.getBlockBlobClient(`${userId}/kit/${filename}`);

    const response = await blobClient.uploadData(file, {
      abortSignal,
      blockSize: 4 * 1024 * 1024,
      blobHTTPHeaders: { blobContentType: file.type },
      onProgress: (ev: TransferProgressEvent) => onProgress(this.calculateProgress(ev, file))
    });

    if (response.errorCode !== undefined) {
      throw new Error(`uploadAssetError: (${response.errorCode})`);
    }
  }

  private calculateProgress (ev: TransferProgressEvent, file: File) {
    return parseInt(((ev.loadedBytes / file.size) * 100).toString(), 10);
  }

  private async uploadResizedImage (containerClient: ContainerClient, userId: number, folder: string, abortSignal: AbortSignal, file: File, filename: string, filetype: string, resizingPercentage: number, maxSize: number, imageProfileName: string, maxFileSize?: string) {
    const fileDetail = this.extensionService.getFilename(filename);
    const newFilename = `${fileDetail.name}_${filetype}.${fileDetail.extension.toLowerCase()}`;
    const blobClient = containerClient.getBlockBlobClient(`${userId}${folder}/resized/${newFilename}`);

    const imageSize = await this.getImageSize(file);
    const scaledSize = this.getScaledSize(imageSize.width, imageSize.height, resizingPercentage, maxSize);
    let resizedImage = await this.resizeImage(file, scaledSize);

    if (maxFileSize && fileDetail.extension.toLowerCase() === "jpg") {
      const maxFileSizeInBytes = this.getFileSizeInBytes(maxFileSize);
      if (resizedImage.size > maxFileSizeInBytes) {
        resizedImage = await this.compressImage(resizedImage, maxFileSizeInBytes);
      }
    }

    return blobClient.uploadData(resizedImage, {
      abortSignal,
      blockSize: 4 * 1024 * 1024,
      blobHTTPHeaders: { blobContentType: file.type },
      metadata: {
        width: scaledSize.width.toString(),
        height: scaledSize.height.toString(),
        filetype,
        imageProfileName
      }
    });
  }

  private getFileSizeInBytes (fileSizeString: string) {
    const units = ["bytes", "kb", "mb", "gb", "tb"];

    fileSizeString = fileSizeString.toLowerCase();

    if (!fileSizeString.includes(" ")) {
      const un = units.find(x => fileSizeString.includes(x))!;
      const idx = fileSizeString.indexOf(un);
      fileSizeString = `${fileSizeString.slice(0, idx)}${" "}${fileSizeString.slice(idx)}`;
    }

    const [size, unit] = fileSizeString.split(" ");

    switch (unit) {
      case "kb":
        return parseFloat(size) * 1024;
      case "mb":
        return parseFloat(size) * Math.pow(1024, 2);
      case "gb":
        return parseFloat(size) * Math.pow(1024, 3);
      case "tb":
        return parseFloat(size) * Math.pow(1024, 4);
      default:
        return parseFloat(size);
    }
  }

  private resizeImage (file: File, size: { width: number; height: number }): Promise<File> {
    return firstValueFrom(
      this.picaService.resizeImage(file, size.width, size.height, {
        exifOptions: {
          forceExifOrientation: false
        },
        aspectRatio: {
          keepAspectRatio: true
        },
        alpha: true
      }));
  }

  private compressImage (file: File, maxFileSizeInBytes: number): Promise<File> {
    const maxFileSizeInMb = maxFileSizeInBytes / 1048576;
    return firstValueFrom(
      this.picaService.compressImage(file, maxFileSizeInMb)
    );
  }

  private getScaledSize (currentWidth: number, currentHeight: number, resizingPercentage: number, maxSize: number) {
    const { maxWidth, maxHeight } = this.getSizeMax(currentWidth, currentHeight, maxSize, maxSize);
    const w = maxWidth * resizingPercentage;
    const h = maxHeight * resizingPercentage;

    return ({ width: Math.trunc(w), height: Math.trunc(h) });
  }

  private getImageSize (file: File): Promise<{ width: number; height: number }> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);

      img.onload = () => resolve({
        width: img.width,
        height: img.height
      });

      img.onerror = (e: any) => reject(e);
    });
  }

  private getSizeMax (currentWidth: number, currentHeight: number, maxWidth: number, maxHeight: number) {
    let maxW = currentWidth;
    let maxH = currentHeight;
    const aspectRatio = currentWidth / currentHeight;

    if (currentWidth > currentHeight) {
      //Portrait
      if (currentWidth > maxWidth) {
        maxW = maxWidth;
        maxH = maxW / aspectRatio;
      }
    }
    else {
      //Landscape
      if (currentHeight > maxHeight) {
        maxH = maxHeight;
        maxW = maxH * aspectRatio;
      }
    }

    return ({ maxWidth: Math.trunc(maxW), maxHeight: Math.trunc(maxH) });
  }
}
