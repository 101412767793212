import { Component, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { EventService } from "@services/support/event.service";
import { NotificationService } from "@services/support/notification.service";
import { State } from "@state/models";
import { CookieService } from "ngx-cookie-service";
import { Subscription } from "rxjs";
import { LAYOUT_HORIZONTAL, LAYOUT_VERTICAL } from "./layouts.model";

@Component({
  selector: "app-layout",
  templateUrl: "./layout.component.html",
  styleUrls: ["./layout.component.scss"]
})
export class LayoutComponent implements OnInit, OnDestroy {
  layoutType: string;
  subs: Subscription;

  constructor (
    private store: Store<State>,
    private cookieService: CookieService,
    private notifService: NotificationService,
    private eventService: EventService) { }

  async ngOnInit () {
    this.layoutType = this.cookieService.get("layoutType") || LAYOUT_VERTICAL;

    if (!this.subs) {
      // listen to event and change the layout, theme, etc
      this.subs = this.eventService.subscribe("changeLayout", (layout) => {
        this.layoutType = layout;
        this.cookieService.set("layoutType", layout, new Date(2099, 12, 31), "/");
      });

      this.notifService.startGettingNotifications();
    }
  }

  ngOnDestroy (): void {
    if (this.subs) {
      this.subs.unsubscribe();
    }
  }

  isVerticalLayoutRequested () {
    return this.layoutType === LAYOUT_VERTICAL;
  }

  isHorizontalLayoutRequested () {
    return this.layoutType === LAYOUT_HORIZONTAL;
  }
}
