import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@environment";
import { Account } from "@models/account/account";
import { AccountPermission } from "@models/account/account-permission";
import { AccountWithFeature } from "@models/account/accountWithFeature";
import { AccountWithPermission } from "@models/account/accountWithPermission";
import { AdminUserModel } from "@models/account/admin-user-model";
import { AccountFeature } from "@models/account/feature";
import { ImageProfile } from "@models/account/image-profile";
import { Plan } from "@models/account/plan";
import { UserSimple } from "@models/user/user-simple";
import { AccountItemState } from "@state/account/models";
import { firstValueFrom } from "rxjs";
import { tap } from "rxjs/operators";
import { Logger } from "../support/logger.service";

@Injectable({
  providedIn: "root"
})
export class AccountService {
  constructor (
    private logger: Logger,
    private http: HttpClient) {
  }

  getUserAccounts (): Promise<AccountItemState[]> {
    return firstValueFrom(
      this.http.get<AccountItemState[]>(`${environment.account.url}/user/list`).pipe(tap({
        next: () => undefined,
        error: err => this.logger.error(err)
      }))
    );
  }

  setUserAccount (accountId: number): Promise<unknown> {
    return firstValueFrom(
      this.http.put(`${environment.account.url}/user`, {
        accountId
      }).pipe(tap({
        next: () => undefined,
        error: err => this.logger.error(err)
      }))
    );
  }

  getFeatures (accountId: number): Promise<AccountFeature> {
    return firstValueFrom(
      this.http.get<AccountFeature>(`${environment.account.url}/${accountId}/features`).pipe(tap({
        next: () => undefined,
        error: err => this.logger.error(err)
      }))
    );
  }

  getAccountList (): Promise<Account[]> {
    return firstValueFrom(
      this.http.get<Account[]>(`${environment.account.url}/list`).pipe(tap({
        next: () => undefined,
        error: err => this.logger.error(err)
      }))
    );
  }

  getAccountUsers (): Promise<UserSimple[]> {
    return firstValueFrom(
      this.http.get<UserSimple[]>(`${environment.account.url}/users`).pipe(tap({
        next: () => undefined,
        error: err => this.logger.error(err)
      }))
    );
  }

  getAccountListWithPermissions (): Promise<AccountWithPermission[]> {
    return firstValueFrom(
      this.http.get<AccountWithPermission[]>(`${environment.account.url}/list/permissions`).pipe(tap({
        next: () => undefined,
        error: err => this.logger.error(err)
      }))
    );
  }

  getAccountPermissions (): Promise<AccountPermission[]> {
    return firstValueFrom(
      this.http.get<AccountPermission[]>(`${environment.account.url}/user/permissions`).pipe(tap({
        next: () => undefined,
        error: err => this.logger.error(err)
      }))
    );
  }

  setAccountStatus (accountId: number, activate: boolean): Promise<unknown> {
    const action = activate ? "activate" : "deactivate";
    return firstValueFrom(
      this.http.put(`${environment.account.url}/${accountId}/${action}`, {}).pipe(tap({
        next: () => undefined,
        error: err => this.logger.error(err)
      }))
    );
  }

  deleteAccount (accountId: number): Promise<unknown> {
    return firstValueFrom(
      this.http.delete(`${environment.account.url}/${accountId}`).pipe(tap({
        next: () => undefined,
        error: err => this.logger.error(err)
      }))
    );
  }

  sendNotification (accountId: number, withPassword?: boolean): Promise<unknown> {
    return firstValueFrom(
      this.http.post(`${environment.account.url}/${accountId}/confirmation`, {
        withPassword,
        link: location.origin
      }).pipe(tap({
        next: () => undefined,
        error: err => this.logger.error(err)
      }))
    );
  }

  getAccount (accountId: number): Promise<AccountWithFeature> {
    return firstValueFrom(
      this.http.get<AccountWithFeature>(`${environment.account.url}/${accountId}`).pipe(tap({
        next: () => undefined,
        error: err => this.logger.error(err)
      }))
    );
  }

  getAccountNameByDomain (domain: string): Promise<{ name: string }> {
    return firstValueFrom(
      this.http.get<{ name: string }>(`${environment.account.url}/${domain}/domain`).pipe(tap({
        next: () => undefined,
        error: err => this.logger.error(err)
      }))
    );
  }

  createAccount (account: AccountWithFeature, adminUser: AdminUserModel, imageProfiles: ImageProfile[]): Promise<{ accountId: number }> {
    return firstValueFrom(
      this.http.post<{ accountId: number }>(`${environment.account.url}`,
        {
          ...account,
          ...adminUser,
          imageProfiles
        }).pipe(tap({
          next: () => undefined,
          error: err => this.logger.error(err)
        }))
    );
  }

  updateAccount (account: AccountWithFeature, imageProfiles: ImageProfile[]): Promise<unknown> {
    return firstValueFrom(
      this.http.put(`${environment.account.url}/${account.accountId}`, {
        ...account,
        imageProfiles
      }).pipe(tap({
        next: () => undefined,
        error: err => this.logger.error(err)
      }))
    );
  }

  getPlans (): Promise<Plan[]> {
    return firstValueFrom(
      this.http.get<Plan[]>(`${environment.account.url}/plans`).pipe(tap({
        next: () => undefined,
        error: err => this.logger.error(err)
      }))
    );
  }

  getImageProfiles (accountId: number): Promise<ImageProfile[]> {
    return firstValueFrom(
      this.http.get<ImageProfile[]>(`${environment.account.url}/${accountId}/image-profiles`).pipe(tap({
        next: () => undefined,
        error: err => this.logger.error(err)
      }))
    );
  }
}
