<div id="layout-wrapper">
  <app-horizontal-topbar (settingsButtonClicked)="onSettingsButtonClicked()" (assetsButtonClicked)="onAssetsButtonClicked()"></app-horizontal-topbar>

  <div class="main-content">
    <div class="page-content">
      <ng-container *ngTemplateOutlet="template"></ng-container>
    </div>
  </div>

  <app-footer></app-footer>
  <app-rightsidebar></app-rightsidebar>
  <app-rightsidebar-asset #rightsidebarAsset></app-rightsidebar-asset>
</div>

<ng-template #template>
  <router-outlet></router-outlet>
</ng-template>
