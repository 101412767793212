import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "@guards/auth.guard";
import { TermsGuard } from "@guards/terms.guard";
import { LoginResolver } from "@resolvers/login.resolver";
import { LayoutComponent } from "./layouts/layout.component";
import { SharingLayoutComponent } from "./layouts/sharing/sharing-layout.component";
import { AuthLayoutComponent } from "./pages/auth/auth-layout.component";

const routes: Routes = [
  { path: "", component: LayoutComponent, loadChildren: () => import("./pages/pages.module").then(m => m.PagesModule), data: { protected: true }, canActivate: [AuthGuard, TermsGuard] },
  { path: "login", component: AuthLayoutComponent, loadChildren: () => import("./pages/auth/auth.module").then(m => m.AuthModule), data: { protected: false, login: true }, resolve: { customer: LoginResolver } },
  { path: "activation", component: AuthLayoutComponent, loadChildren: () => import("./pages/auth/activation/activation.module").then(m => m.ActivationModule), data: { protected: false, login: false }, resolve: { customer: LoginResolver } },
  { path: "sharing", component: SharingLayoutComponent, loadChildren: () => import("./pages/sharing/sharing.module").then(m => m.SharingModule), data: { protected: false } },
  { path: "terms", loadChildren: () => import("./pages/terms/terms.module").then(m => m.TermsModule), data: { protected: true }, canActivate: [AuthGuard] },
  { path: "sso", loadChildren: () => import("./pages/auth/sso/sso.module").then(m => m.SsoModule), data: { protected: false } },
  { path: "error", loadChildren: () => import("./pages/error/error.module").then(m => m.ErrorModule), data: { protected: false } },
  { path: "**", redirectTo: "error/404", data: { protected: false } }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false, scrollPositionRestoration: "top", relativeLinkResolution: "legacy" })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
