import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { select, Store } from "@ngrx/store";
import authActions from "@state/auth/actions";
import selectors from "@state/auth/selectors";
import { State } from "@state/models";
import { environment } from "environments/environment";
import { CookieService } from "ngx-cookie-service";
import { EMPTY, firstValueFrom, from, Observable, throwError } from "rxjs";
import { catchError, switchMap, take } from "rxjs/operators";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor (
    private router: Router,
    private cookiesService: CookieService,
    private store: Store<State>) { }

  intercept (request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.getToken()).pipe(
      switchMap(token => {
        if (token) {
          request = request.clone({
            setHeaders: {
              authorization: `Bearer ${token}`
            }
          });
        }

        request = request.clone({
          setHeaders: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            "ocp-apim-subscription-key": environment.apiKey
          }
        });

        return next.handle(request).pipe(
          catchError(err => {
            if (err.status === 401) {
              localStorage.clear();
              sessionStorage.clear();
              this.cookiesService.delete("sessionId", "/");
              this.store.dispatch(authActions.signedOut());
              this.router.navigate(["/login"]);
              return EMPTY;
            } else {
              return throwError(err);
            }
          })
        );
      })
    );
  }
  handleAuthError (err: any) {
    console.log(err);
  }

  async getToken (): Promise<string | undefined> {
    return await firstValueFrom(
      this.store
        .pipe(
          select(selectors.accessToken),
          take(1)
      )
    );
  }
}
