import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { AppModule } from "./app/app.module";
import { Logger } from "./app/core/services/support/logger.service";
import { environment } from "./environments/environment";


if (environment.production) {
  enableProdMode();
  Logger.enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
