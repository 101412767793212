import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@environment";
import { Customer } from "@models/account/customer";
import { firstValueFrom } from "rxjs";
import { tap } from "rxjs/operators";
import { Logger } from "../support/logger.service";

@Injectable({
  providedIn: "root"
})
export class CustomerService {
  constructor (
    private logger: Logger,
    private http: HttpClient) {
  }

  getDomainKey (suffix: string): string {
    return `${location.hostname}-${suffix}`;
  }

  getLocal (): Customer {
    const key = this.getDomainKey("customer");
    const value = sessionStorage.getItem(key) || JSON.stringify({ type: "Local", styleName: "app", exists: false });
    return JSON.parse(value);
  }

  setLocal (customer: Customer) {
    const key = this.getDomainKey("customer");
    const value = JSON.stringify(customer);
    sessionStorage.setItem(key, value);
  }

  getByDomain (): Promise<Customer> {
    return firstValueFrom(
      this.http.get<Customer>(`${environment.account.url}/customer/domain?d=${location.hostname}`)
        .pipe(tap({
          next: () => undefined,
          error: err => this.logger.error(err)
        }))
    );
  }
}
