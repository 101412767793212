import { DefaultUrlSerializer, UrlTree } from "@angular/router";

export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
  parse (url: string): UrlTree {
    if (url.includes("sso")) {
      return super.parse(url);
    } else {
      return super.parse(url.toLowerCase());
    }
  }
}
