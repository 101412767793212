<div id="layout-wrapper">
  <app-topbar [sharing]="true"></app-topbar>

  <div class="main-content">
    <div class="page-content">
      <router-outlet></router-outlet>
    </div>
    <app-footer></app-footer>
  </div>
</div>
