import { HttpClient, HttpContext } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Notification } from "@models/support/notification";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngrx/store";
import { NGX_LOADING_BAR_IGNORED } from "@ngx-loading-bar/http-client";
import selectors from "@state/auth/selectors";
import { State } from "@state/models";
import { environment } from "environments/environment";
import { firstValueFrom, Subscription, timer } from "rxjs";
import { tap } from "rxjs/operators";
import { EventService } from "./event.service";
import { Logger } from "./logger.service";

@UntilDestroy()
@Injectable({
  providedIn: "root"
})
export class NotificationService {
  userIsSignedIn$ = this.store.select(selectors.isLoaded);
  subs?: Subscription;

  constructor (
    private eventService: EventService,
    private logger: Logger,
    private http: HttpClient,
    private store: Store<State>) {
  }

  startGettingNotifications () {
    this.userIsSignedIn$.pipe(untilDestroyed(this)).subscribe(signedIn => {
      if (signedIn) {
        if (!this.subs) {
          this.subs = timer(0, environment.notificationPollInterval)
            .pipe(untilDestroyed(this))
            .subscribe(async () => await this.getAndBroadcastNotifications());
        }
      }
      else {
        if (this.subs) {
          this.subs.unsubscribe();
          this.subs = undefined;
        }
      }
    });
  }

  async getAndBroadcastNotifications () {
    const notifications = await this.getNotifications();

    if (notifications) {
      this.eventService.broadcast("load_notifications", notifications);
    }
  }

  async getNotifications () {
    try {
      return await firstValueFrom(this.http.get<Notification[]>(`${environment.catalog.url}/notifications`, { context: new HttpContext().set(NGX_LOADING_BAR_IGNORED, true) }));
    }
    catch (err) {
      this.logger.error(err);
      return undefined;
    }
  }

  markAsRead (): Promise<unknown> {
    return firstValueFrom(
      this.http.put(`${environment.catalog.url}/notifications/mark-as-read`, {}, { context: new HttpContext().set(NGX_LOADING_BAR_IGNORED, true) }).pipe(tap({
        next: () => undefined,
        error: err => this.logger.error(err)
      }))
    );
  }
}
