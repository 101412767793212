import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngrx/store";
import { InsightsService } from "@services/support/insights.service";
import { Logger } from "@services/support/logger.service";
import authActions from "@state/auth/actions";
import selectors from "@state/auth/selectors";
import { State } from "@state/models";
import { CookieService } from "ngx-cookie-service";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@UntilDestroy()
@Injectable({
  providedIn: "root"
})
export class AuthGuard implements CanActivate, CanActivateChild {
  userIsSignedIn$ = this.store.select(selectors.isLoaded);

  constructor (
    private router: Router,
    private insightsService: InsightsService,
    private cookieService: CookieService,
    private logger: Logger,
    private store: Store<State>) {
  }

  canActivate (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const returnUrl = state.url === "/terms" ? "/" : state.url;
    const session = this.cookieService.check("sessionId");

    if (!session) {
      localStorage.clear();
      sessionStorage.clear();
      this.insightsService.clearUserId();

      const isProtectedRoute = route.data?.protected ?? true;

      if (isProtectedRoute) {
        this.logger.info("session does not exist and route is protected... redirecting to login page");
        this.store.dispatch(authActions.signedOut());
        return this.router.parseUrl(`/login?returnUrl=${returnUrl}`);
      }
    }

    return this.userIsSignedIn$.pipe(untilDestroyed(this)).pipe(map(signedIn => {
      if (!signedIn) {
        this.logger.info("user not signedin... redirecting to login page");
        return this.router.parseUrl(`/login?returnUrl=${returnUrl}`);
      }
      return signedIn;
    }));
  }

  canActivateChild (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(route, state);
  }
}
