import { createSelector } from "@ngrx/store";
import { LoadingState, State } from "@state/models";
import { UserState } from "@state/user/models";
import { AccountState } from "./models";

const slice = (state: State) => state.account;

export const isLoading = createSelector(slice,
  (state: AccountState) => state.state === LoadingState.loading
);

export const isLoaded = createSelector(slice,
  (state: AccountState) => state.state === LoadingState.loaded
);

export const error = createSelector(slice,
  (state: AccountState) => state.error
);

export const accounts = createSelector(
  (state: State) => state.user,
  (state: State) => state.account,
  (user: UserState, accs: AccountState) => {
    if (user && accs) {
      return accs.items.map(item => ({
        ...item,
        current: user.currentAccountId === item.accountId
      }));
    } else {
      return accs.items.map(item => ({
        ...item,
        current: false
      }));
    }
  }
);

export const currentAccount = createSelector(
  accounts,
  account => account.find(x => x.current)
);

export default {
  isLoading,
  isLoaded,
  error,
  accounts,
  currentAccount
};
