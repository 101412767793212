import { AccountFeature } from "./account/feature";
import { PermissionType } from "./auth/permission-type";

export interface MenuItem {
  id?: number;
  label: string;
  icon?: string;
  link?: string;
  subItems?: any;
  isTitle?: boolean;
  badge?: any;
  parentId?: number;
  isLayout?: boolean;
  show?: boolean;
}

export const getMenu = (features: AccountFeature, permission: PermissionType, isAdmMaster: boolean) => {
  const MENU: MenuItem[] = [
    {
      id: 0,
      label: "menuitems.categories.system",
      isTitle: true,
      show: true,
    },
    {
      id: 10,
      label: "Home",
      link: "/",
      icon: "bx bx-home-circle",
      show: true,
    },
    {
      id: 20,
      label: "menuitems.cm.text",
      icon: "bx bx-photo-album",
      show: features.userGallery && permission !== PermissionType.Externo,
      subItems: [
        {
          id: 2010,
          label: "menuitems.cm.assetsUpload",
          link: "/cm/upload",
          parentId: 20,
          badge: {
            class: "badge-upload",
            variant: "primary",
            icon: "mdi mdi-upload",
            show: true,
          },
          show:
            (features.itemCreation || features.multipleFileUpload) &&
            ![
              PermissionType.SimplesPlus,
              PermissionType.Simples,
              PermissionType.SimplesLight,
            ].includes(permission),
        },
        {
          id: 2020,
          label: "menuitems.cm.gallery",
          link: "/cm/gallery",
          parentId: 20,
          show: true,
        },
        {
          id: 2040,
          label: "menuitems.cm.favorites",
          link: "/cm/favorite",
          parentId: 20,
          show:
            features.favorites &&
            ![PermissionType.Simples].includes(permission),
        },
        {
          id: 2050,
          label: "menuitems.cm.downloads",
          link: "/cm/download-queue",
          parentId: 20,
          badge: {
            variant: "info",
            text: "0",
            show: false,
          },
          show:
            features.downloadQueue &&
            permission !== PermissionType.Simples &&
            permission !== PermissionType.SimplesLight,
        },
      ],
    },
    {
      id: 30,
      label: "menuitems.aprovio.text",
      icon: "bx bx-cube",
      link: "/aprovio/approvals",
      show: features.aprovioDashboard,
    },
    // {
    //   id: 30,
    //   label: "menuitems.wf.text",
    //   icon: "bx bx-cube",
    //   show: features.projects && permission !== PermissionType.Simples,
    //   subItems: [
    //     {
    //       id: 3010,
    //       label: "menuitems.wf.projects.text",
    //       link: "/wf/projects",
    //       parentId: 30,
    //       show: true,
    //       subItems: [
    //         {
    //           id: 301010,
    //           label: "menuitems.wf.projects.register",
    //           link: "/wf/projects",
    //           parentId: 3010,
    //           show: features.projects && [PermissionType.Administrador, PermissionType.AdministradorBasic, PermissionType.Normal].includes(permission),
    //         },
    //         {
    //           id: 301020,
    //           label: "menuitems.wf.projects.list",
    //           link: "/wf/projects",
    //           parentId: 3010,
    //           show: true
    //         },
    //         {
    //           id: 301030,
    //           label: "menuitems.wf.projects.templates",
    //           link: "/wf/projects",
    //           parentId: 3010,
    //           show: true
    //         },
    //       ]
    //     },
    //     {
    //       id: 3020,
    //       label: "menuitems.wf.dashboard.text",
    //       icon: "bx bx-cube",
    //       show: features.workflowDashboard,
    //       subItems: [
    //         {
    //           id: 302010,
    //           label: "menuitems.wf.dashboard.projects",
    //           link: "/wf/projects",
    //           parentId: 3020,
    //           show: true
    //         },
    //         {
    //           id: 302030,
    //           label: "menuitems.wf.dashboard.teams",
    //           link: "/wf/projects",
    //           parentId: 3020,
    //           show: true
    //         },
    //       ]
    //     },
    //   ],
    // },
    {
      id: 40,
      label: "menuitems.admin.text",
      icon: "bx bx-receipt",
      show: [
        PermissionType.Administrador,
        PermissionType.AdministradorBasic,
      ].includes(permission),
      subItems: [
        {
          id: 4010,
          label: "menuitems.admin.dashboard",
          link: "/admin/dashboard",
          parentId: 40,
          show: true,
        },
        {
          id: 4020,
          label: "menuitems.admin.teams",
          link: "/admin/teams",
          parentId: 40,
          show: features.userGroupCreation,
        },
        {
          id: 4030,
          label: "menuitems.admin.users",
          link: "/admin/users",
          parentId: 40,
          show: true,
        },
        {
          id: 4040,
          label: "menuitems.admin.categories",
          link: "/admin/attributes",
          parentId: 40,
          show: features.itemCategoryCreation && features.attributes,
        },
        {
          id: 4040,
          label: "menuitems.admin.categories",
          link: "/admin/categories",
          parentId: 40,
          show: features.itemCategoryCreation && !features.attributes,
        },
        {
          id: 4050,
          label: "menuitems.admin.clients",
          link: "/admin/clients",
          parentId: 40,
          show: features.clients,
        },
        {
          id: 4055,
          label: "menuitems.admin.terms",
          link: "/admin/terms",
          parentId: 40,
          show: features.assetTermsOfUse,
        },
        {
          id: 4060,
          label: "menuitems.admin.config",
          show: true,
          subItems: [
            {
              id: 406010,
              label: "menuitems.admin.configTemplate",
              link: "/admin/config",
              parentId: 4060,
              show: true,
            },
            {
              id: 406020,
              label: "menuitems.admin.configLink",
              link: "/admin/link",
              parentId: 4060,
              show: features.sharingCodeValidation,
            },
          ],
        },
        {
          id: 4070,
          label: "menuitems.admin.selfreg",
          link: "/admin/self-registration",
          parentId: 40,
          show: features.selfRegistration,
        },
        {
          id: 4070,
          label: "menuitems.admin.store",
          link: "/admin/stores",
          parentId: 40,
          show: features.store,
        },
      ],
    },
    {
      id: 50,
      label: "menuitems.reports.text",
      icon: "bx bx-line-chart",
      show: [
        PermissionType.Administrador,
        PermissionType.AdministradorBasic,
      ].includes(permission),
      subItems: [
        {
          id: 5010,
          label: "menuitems.reports.cm.text",
          link: "/report/cm",
          parentId: 50,
          show:
            features.itemsReport ||
            features.itemsToExpire ||
            features.kitReport ||
            features.uploadsByUserReport ||
            features.downloadsByUserReport ||
            features.categoriesReport ||
            features.groupsReport,
          subItems: [
            {
              id: 501010,
              label: "menuitems.reports.cm.items",
              link: "/report/cm/items",
              parentId: 5010,
              show: features.itemsReport,
            },
            {
              id: 501020,
              label: "menuitems.reports.cm.itemsToExpire",
              link: "/report/cm/items-expire",
              parentId: 5010,
              show: features.itemsToExpire,
            },
            {
              id: 501030,
              label: "menuitems.reports.cm.kits",
              link: "/report/cm/kits",
              parentId: 5010,
              show: features.kitReport,
            },
            {
              id: 501040,
              label: "menuitems.reports.cm.uploadByUser",
              link: "/report/cm/uploads-user",
              parentId: 5010,
              show: features.uploadsByUserReport,
            },
            {
              id: 501050,
              label: "menuitems.reports.cm.downloadByUser",
              link: "/report/cm/downloads-user",
              parentId: 5010,
              show: features.downloadsByUserReport,
            },
            {
              id: 501060,
              label: "menuitems.reports.cm.categories",
              link: "/report/cm/categories",
              parentId: 5010,
              show: features.categoriesReport,
            },
            {
              id: 501070,
              label: "menuitems.reports.cm.teams",
              link: "/report/cm/teams",
              parentId: 5010,
              show: features.groupsReport,
            },
            {
              id: 501080,
              label: "menuitems.reports.cm.downloadQueue",
              link: "/report/cm/download-queue",
              parentId: 5010,
              show: features.downloadQueueReport,
            },
          ],
        },
        {
          id: 5020,
          label: "menuitems.reports.wf.text",
          link: "/report/wf",
          parentId: 50,
          show:
            features.projectsReport ||
            features.feedbacksReport ||
            features.equipesReport ||
            features.userProjectsReport,
          subItems: [
            {
              id: 502010,
              label: "menuitems.reports.wf.projects",
              link: "/report/wf/projects",
              parentId: 5020,
              show: features.projectsReport,
            },
            {
              id: 502020,
              label: "menuitems.reports.wf.feedbacks",
              link: "/report/wf/feedbacks",
              parentId: 5020,
              show: features.feedbacksReport,
            },
            {
              id: 502030,
              label: "menuitems.reports.wf.clients",
              link: "/report/wf/clients",
              parentId: 5020,
              show: features.equipesReport,
            },
            {
              id: 502040,
              label: "menuitems.reports.wf.usersByProject",
              link: "/report/wf/user-projects",
              parentId: 5020,
              show: features.userProjectsReport,
            },
          ],
        },
        {
          id: 5030,
          label: "menuitems.reports.admin.text",
          link: "/report/admin",
          parentId: 50,
          show:
            features.usersReport ||
            features.accessLog ||
            features.externalShare ||
            features.facebook,
          subItems: [
            {
              id: 503010,
              label: "menuitems.reports.admin.users",
              link: "/report/admin/users",
              parentId: 5030,
              show: features.usersReport,
            },
            {
              id: 503020,
              label: "menuitems.reports.admin.accessLogs",
              link: "/report/admin/access-log",
              parentId: 5030,
              show: features.accessLog,
            },
            {
              id: 503025,
              label: "menuitems.reports.admin.accessLogsPublic",
              link: "/report/admin/public-access-log",
              parentId: 5030,
              show: features.huntagPublic,
            },
            {
              id: 503030,
              label: "menuitems.reports.admin.externalAccess",
              link: "/report/admin/external-access",
              parentId: 5030,
              show: features.externalShare,
            },
            {
              id: 503040,
              label: "menuitems.reports.admin.socialMedia",
              link: "/report/admin/social-media",
              parentId: 5030,
              show: features.facebook,
            },
          ],
        },
      ],
    },
    {
      id: 70,
      label: "Release Notes",
      link: "/release-notes",
      icon: "bx bx-news",
      badge: {
        variant: "info",
        text: "new",
        show: false,
      },
      show: true,
    },
    {
      id: 90,
      label: "menuitems.categories.master",
      isTitle: true,
      show: isAdmMaster,
    },
    {
      id: 91,
      label: "menuitems.master.config.text",
      icon: "bx bx-cog",
      show: isAdmMaster,
      subItems: [
        {
          id: 92,
          label: "menuitems.master.config.dashboard",
          link: "/master/dashboard",
          icon: "bx bx-bar-chart-square",
          show: true,
        },
        {
          id: 93,
          label: "menuitems.master.config.accounts",
          link: "/master/accounts",
          icon: "bx bx-aperture",
          show: true,
        },
        {
          id: 94,
          label: "menuitems.master.config.users",
          link: "/master/users",
          icon: "bx bx-user-circle",
          show: true,
        },
        {
          id: 95,
          label: "menuitems.master.config.emailConfigs",
          link: "/master/emails",
          icon: "bx bx-envelope",
          show: true,
        },
        {
          id: 96,
          label: "menuitems.master.config.releaseNotes",
          link: "/master/release-notes",
          icon: "bx bx-news",
          show: true,
        },
      ],
    },
    {
      id: 97,
      label: "menuitems.master.access.text",
      icon: "bx bx-log-in-circle",
      show: isAdmMaster,
      subItems: [
        {
          id: 9710,
          label: "menuitems.master.access.last",
          link: "/master/reports/last-access",
          parentId: 97,
          show: true,
        },
        {
          id: 9720,
          label: "menuitems.master.access.count",
          link: "/master/reports/access-count",
          parentId: 97,
          show: true,
        },
      ],
    },
    {
      id: 60,
      label: "menuitems.categories.config",
      isTitle: true,
      show: true,
    },
    {
      id: 70,
      isLayout: true,
      isTitle: true,
      label: "",
    },
  ];

  return MENU;
};
