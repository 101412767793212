import { createReducer, on } from "@ngrx/store";
import authActions from "@state/auth/actions";
import { LoadingState } from "@state/models";
import actions from "./actions";
import { TermAssetState } from "./models";

const initialState: TermAssetState = {
  enableTermsOfUse: false,
  enableAllTerms: false,
  state: LoadingState.init,
  error: null
};

export const termAssetReducer = createReducer(
  initialState,
  on(actions.termAssetLoading, (state) => ({ ...state, state: LoadingState.loading, error: null })),
  on(actions.termAssetLoaded, (state, action) => ({ ...state, enableTermsOfUse: action.assetTerm.enableTermsOfUse, enableAllTerms: action.assetTerm.enableAllTerms, term: action.assetTerm.term, state: LoadingState.loaded, error: null })),
  on(actions.reset, () => ({ ...initialState })),
  on(actions.error, (state, action) => ({ ...state, state: LoadingState.error, error: action.error })),
  on(authActions.signedOut, () => ({ ...initialState }))
);
