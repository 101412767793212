export default {
  production: false,
  name: "development",
  maxGalleryItems: 10,
  maxGalleryItemsKit: 100,
  notificationPollInterval: 30000,
  uploadQueueLength: 5,
  apiKey: "",
  freshChat: { token: "ace072d6-44f5-4ad5-8b17-2d5f1164107e", host: "https://wchat.freshchat.com" },
  appInsightsCnnString: "InstrumentationKey=2f165cb7-6e44-4185-ba4c-1dd87c0a443d;IngestionEndpoint=https://brazilsouth-1.in.applicationinsights.azure.com/;LiveEndpoint=https://brazilsouth.livediagnostics.monitor.azure.com/",
  storageUrl: "https://huntaghom.blob.core.windows.net",
  identity: { url: "https://localhost:44309" },
  catalog: { url: "https://localhost:44310" },
  account: { url: "https://localhost:44311" },
  aprovio: { url: "https://localhost:44312" },
  report: { url: "https://localhost:44313" }
};
