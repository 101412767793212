import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from "@angular/router";
import { Customer } from "@models/account/customer";
import { CustomerService } from "@services/account/customer.service";
import { Logger } from "@services/support/logger.service";

@Injectable({
  providedIn: "root"
})
export class LoginResolver implements Resolve<Customer> {

  constructor (
    private logger: Logger,
    private router: Router,
    private customerService: CustomerService) {
  }

  async resolve (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Customer> {
    let customer = this.customerService.getLocal();
    const returnUrl = route.queryParamMap.get("returnUrl") || "/";

    if (!customer.exists) {
      try {
        customer = await this.customerService.getByDomain();
        customer.exists = true;
        this.customerService.setLocal(customer);
      }
      catch (err: any) {
        this.logger.error(err);
      }
    }

    const isLogin = route.data?.login ?? false;
    const isSso = customer.type === "OidcImplicitFlow" || customer.type === "OidcCodeFlow";

    if (isLogin && isSso) {
      this.router.navigate(["/sso"], { queryParams: { returnUrl } });
    }

    return customer;
  }
}
