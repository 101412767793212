import { createReducer, on } from "@ngrx/store";
import { LoadingState } from "@state/models";
import actions from "./actions";
import { AuthState } from "./models";

const initialState: AuthState = {
  state: LoadingState.init,
  error: null
};

export const authReducer = createReducer(
  initialState,
  on(actions.signingIn, (state) => ({ ...state, state: LoadingState.loading, error: null })),
  on(actions.signedIn, (state, action) => ({ ...state, ...action.auth, state: LoadingState.loaded, error: null })),
  on(actions.signedOut, () => ({ ...initialState })),
  on(actions.reset, () => ({ ...initialState })),
  on(actions.error, (state, action) => ({ ...state, state: LoadingState.error, error: action.error }))
);
