import { createSelector } from "@ngrx/store";
import { LoadingState, State } from "@state/models";
import { SelectedAssetState } from "./models";

const slice = (state: State) => state.selectedAsset;

export const isLoading = createSelector(slice,
  (state: SelectedAssetState) => state.state === LoadingState.loading
);

export const isLoaded = createSelector(slice,
  (state: SelectedAssetState) => state.state === LoadingState.loaded
);

export const error = createSelector(slice,
  (state: SelectedAssetState) => state.error
);

export const fileIds = createSelector(slice,
  (state: SelectedAssetState) => state.fileIds
);

export default {
  isLoading,
  isLoaded,
  error,
  fileIds
};
