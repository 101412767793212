<div id="layout-wrapper">
  <app-topbar (settingsButtonClicked)="onSettingsButtonClicked()" (assetsButtonClicked)="onAssetsButtonClicked()" (mobileMenuButtonClicked)="onToggleMobileMenu()"></app-topbar>
  <app-sidebar [isCondensed]="isCondensed"></app-sidebar>

  <div class="main-content">
    <div class="page-content">
      <ng-container *ngTemplateOutlet="template"></ng-container>
    </div>
    <app-footer></app-footer>
  </div>
</div>

<app-rightsidebar></app-rightsidebar>
<app-rightsidebar-asset #rightsidebarAsset></app-rightsidebar-asset>

<ng-template #template>
  <router-outlet></router-outlet>
</ng-template>
